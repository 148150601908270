<script setup lang="ts">
import DefaultLayout from '@layouts/default.vue'
import { useHead } from '@unhead/vue'
import { watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { z } from 'zod'

import { useZod } from '@epostbox/shared/zod'
import { Toaster } from '@epostbox/ui/sonner'
import { TooltipProvider } from '@epostbox/ui/tooltip'

import { useNative } from '@composables/use-native'

const { errorMap } = useZod()
// @ts-expect-error types are bad but it works
z.setErrorMap(errorMap)

const { init } = useNative()
init()

const { t } = useI18n()
const route = useRoute()

watchEffect(() => {
  const title = route.meta.title
  useHead({
    title: title ? `${t(`page_title.app.${title}`)}` : 'Nolas Network',
  })
})
</script>

<template>
  <TooltipProvider>
    <DefaultLayout>
      <Toaster rich-colors position="bottom-center" close-button />

      <RouterView />
    </DefaultLayout>
  </TooltipProvider>
</template>
