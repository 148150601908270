import { GetSearchKey } from '@services/keyflowz-api'
import { useQuery } from '@tanstack/vue-query'
import { Ref } from 'vue'

import { ServiceError } from '@epostbox/shared/errors'

import { useUser } from '@composables/use-user'

export function useSearchKey() {
  const { user } = useUser()

  const {
    data: searchCreds,
    error,
    ...queryRest
  } = useQuery({
    enabled: () => !!user.value,
    queryKey: ['searchKey'] as const,
    retry: 2,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    staleTime: 60_000,
    queryFn: async () => {
      const { response, data, error } = await GetSearchKey({
        params: { path: { workspaceId: user.value!.workspaceId } },
      })

      if (!response.ok || !data) {
        throw ServiceError.fromResponse(error)
      }

      return data
    },
  })

  return { searchCreds, error: error as Ref<ServiceError | null>, ...queryRest }
}
