<script lang="ts" setup>
import type { HTMLAttributes } from 'vue'

import { cn } from '../../utils'
import { AppIcon } from '../icon'

const props = defineProps<{
  class?: HTMLAttributes['class']
}>()
</script>

<template>
  <li role="presentation" aria-hidden="true" :class="cn('[&>svg]:size-3.5', props.class)">
    <slot>
      <AppIcon icon="chevron-right" class="h-4 w-4 min-w-4" />
    </slot>
  </li>
</template>
