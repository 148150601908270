export default {
  beforeMount(el: HTMLInputElement) {
    el.addEventListener('input', event => {
      const target = event.target as HTMLInputElement
      if (!target) return
      let value = target?.value
      // Remove any invalid characters
      value = value.replaceAll(/\D/g, '')

      // Update the input value
      target.value = value

      // Trigger an input event to notify Vue of the change
      target.dispatchEvent(new Event('input'))
    })
  },
}
