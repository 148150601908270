<script setup lang="ts">
import { useNavigation } from '@layouts/navigation.ts'
import ChevornLeftDouble from '~icons/epostbox/chevron-left-double'

import { IconButton } from '../../components/icon-button'
import { cn } from '../../utils'

const { toggleMenuFold, isMenuUnfolded } = useNavigation()
</script>

<template>
  <div
    :class="
      cn(
        isMenuUnfolded ? 'invisible z-[-1]' : 'visible z-10',
        'absolute left-0 top-0 flex h-screen w-20 flex-col items-center bg-white px-4 pt-3 transition-all'
      )
    "
  >
    <div class="relative h-14">
      <IconButton size="md" variant="gray" class="open-left-drawer" data-e2e="open-left-drawer" @click="toggleMenuFold">
        <ChevornLeftDouble class="pointer-events-none h-6 w-6" />
      </IconButton>
    </div>
  </div>
</template>
