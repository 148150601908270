<script setup lang="ts">
import { uniqBy } from 'lodash'
import { computed } from 'vue'
import { useRoute, RouterLink } from 'vue-router'

import { useBreadcrumbs } from './use-breadcrumbs'

import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from '.'

const { getBreadcrumbLabel } = useBreadcrumbs()

const route = useRoute() || {}

const uniqRouteMatched = computed(() => uniqBy(route.matched, 'path'))
</script>

<template>
  <Breadcrumb class="flex items-center">
    <BreadcrumbList>
      <BreadcrumbItem>
        <BreadcrumbPage class="text-brand-1000">{{ $t('common.brand_name') }}</BreadcrumbPage>
      </BreadcrumbItem>
      <template v-for="(link, index) in route.matched" :key="link.meta.title">
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbPage v-if="index === uniqRouteMatched?.length - 1">
            {{ getBreadcrumbLabel(link.meta.title) }}
          </BreadcrumbPage>
          <BreadcrumbLink v-else as-child>
            <RouterLink :to="link.path" class="text-xs font-medium">
              {{ getBreadcrumbLabel(link.meta.title) }}
            </RouterLink>
          </BreadcrumbLink>
        </BreadcrumbItem>
      </template>
      <template v-if="route.query?.pageName">
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbPage>{{ route.query?.pageName }}</BreadcrumbPage>
        </BreadcrumbItem>
      </template>
    </BreadcrumbList>
  </Breadcrumb>
</template>
