import { GetToken, TokenURL } from '@services/auth-api'
import { setAccessToken } from '@services/retrieve-access-token'
import { useQuery } from '@tanstack/vue-query'
import { useUrlSearchParams } from '@vueuse/core'
import { type Ref, computed } from 'vue'

import { ServiceError } from '@epostbox/shared/errors'

import { useNative } from './use-native'
import { useNotification } from './use-notification'

const { initWebSocket } = useNotification()

export function useSession() {
  const { isNative, sendMessage } = useNative()
  const searchParams = useUrlSearchParams<{ code?: string }>()

  const {
    data: token,
    error,
    ...query
  } = useQuery({
    queryKey: ['session'],
    refetchInterval: 5 * 60 * 1000,
    retry: false,
    staleTime: 1000 * 60 * 5,
    queryFn: async () => {
      // only exists in the desktop app.
      const refresh_token = localStorage.getItem('refresh_token')

      const { response, data, error } = await GetToken({
        body: {
          client_id: isNative ? 'app' : 'web',
          grant_type: 'refresh_token',
          ...(isNative && refresh_token && !searchParams.code && { refresh_token }),
          ...(searchParams.code && {
            grant_type: 'authorization_code',
            code: searchParams.code,
            code_verifier: getCodeVerifier()!,
          }),
        },
        bodySerializer(body) {
          return new URLSearchParams(body)
        },
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
      })

      if (!response.ok) {
        throw ServiceError.fromResponse(error)
      }

      const result = data!

      setAccessToken(result.access_token)
      initWebSocket(result.access_token)
      if (isNative) {
        localStorage.setItem('refresh_token', result.refresh_token!)
        sendMessage({ access_token: result.access_token, token_url: TokenURL })
      }
      return result.access_token
    },
  })

  const isLoggedIn = computed(() => !!token.value)

  return { isLoggedIn, error: error as Ref<ServiceError | null>, ...query }
}

function getCodeVerifier() {
  return sessionStorage.getItem('code_verifier')
}
