import { useQuery } from '@tanstack/vue-query'
import { Client } from 'typesense'
import { computed, MaybeRef, Ref } from 'vue'

import { Space } from '@epostbox/core-workbench/schema'
import type { Document } from '@epostbox/core-workbench/typesense'
import { ServiceError } from '@epostbox/shared/errors'

import { Filter } from '@modules/workbench/composables/use-table-state'

import { useSearchKey } from './use-search-key'

const documentsCollection = 'document_assignments'

export interface DocumentsSearchInput {
  q?: string
  page?: number
  limit?: number
  space?: Space
  filterBy?: Filter
}

export function useDocumentSearch(search: MaybeRef<DocumentsSearchInput>, options?: { refetch?: boolean }) {
  const { searchCreds } = useSearchKey()

  const searchClient = computed(() => {
    if (!searchCreds.value) return

    const url = new URL(searchCreds.value.url)

    return new Client({
      nodes: [
        {
          host: url.hostname,
          port: url.protocol === 'https:' ? 443 : Number(url.port || 80),
          protocol: url.protocol.replace(':', ''),
        },
      ],
      apiKey: searchCreds.value.key,
      connectionTimeoutSeconds: 10,
    })
  })

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const filterBy = (filters?: Filter) =>
    Object.entries(filters || {}).flatMap(([key, value]) => (value ? `${key} := ${value}` : []))

  const {
    data: searchResult,
    error,
    ...queryRest
  } = useQuery({
    queryKey: ['search', search] as const,
    enabled: () => !!searchCreds.value,
    retry: 2,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    staleTime: 1000,
    // prettier-ignore
    refetchInterval: import.meta.env.DEV ? undefined : (options?.refetch ? 2000 : undefined),
    queryFn: async ({ queryKey: [, search] }) => {
      const filter_by = [`space := ${search.space ?? 'DRAFTS'}`, ...filterBy(search.filterBy as Filter)]
        .flat()
        .join(' && ')

      const searchResults = await searchClient
        .value!.collections<Document>(documentsCollection)
        .documents()
        .search(
          {
            q: search.q ?? '*',
            per_page: search.limit,
            page: search.page,
            query_by: 'name',
            filter_by,
            num_typos: '1',
            infix: 'fallback',
          },
          {}
        )

      return searchResults
    },
  })

  return { searchResult, error: error as Ref<ServiceError | null>, ...queryRest }
}
