<script setup lang="ts">
import { type MenuItem } from '@layouts/navigation.ts'
import { ref } from 'vue'
import ChevronRight from '~icons/epostbox/chevron-right'

import { Badge } from '../../components/badge'
import { Button } from '../../components/button'

defineProps<{
  item: MenuItem
  isSelected?: boolean
  hasRightChevron?: boolean
}>()

const isHovered = ref(false)
</script>

<template>
  <Button
    v-if="item.type !== 'spacer'"
    size="md"
    variant="menu"
    :class="[
      'my-1 w-full justify-start gap-3 text-left',
      isSelected ? 'bg-gray-275' : '',
      hasRightChevron ? 'rounded-none' : '',
    ]"
    :data-e2e="`app-bar-navigation-bar-item-${item.id}`"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <component
      :is="item.icon"
      v-if="item.icon"
      :class="['!h-6 !w-6', isSelected ? 'text-gray-1000' : 'text-gray-150']"
    />
    {{ $t(item.name) }}
    <Badge v-if="item.badge" :variant="isSelected || isHovered ? 'white' : 'beige'" size="sm" class="ml-auto">
      {{ item.badge }}
    </Badge>
    <ChevronRight v-if="hasRightChevron" class="ml-auto !h-6 !w-6" />
  </Button>
  <div v-else class="h-4 bg-gray-warm-50" />
</template>
