import { z } from 'zod'

const ExtraErrorCode = z.util.arrayToEnum(['required'])
type ExtraErrorCode = keyof typeof ExtraErrorCode
type ErrorCode = ExtraErrorCode | z.ZodIssueCode

type RequiredIssue = z.ZodIssueBase & {
  code: typeof ExtraErrorCode.required
  expected: z.ZodParsedType
  received: 'undefined'
}

type Issue<Code extends ErrorCode> = Code extends RequiredIssue['code']
  ? RequiredIssue
  : Code extends z.ZodIssueCode
    ? z.ZodIssueOptionalMessage & { code: Code }
    : never

type ErrorMapMessageBuilderContext<Code extends ErrorCode> = z.ErrorMapCtx & Issue<Code>

type ErrorMapMessage = string | { message: string; variables?: Record<string, unknown> }

type ErrorMapMessageBuilder<Code extends ErrorCode> = (context: ErrorMapMessageBuilderContext<Code>) => ErrorMapMessage

type ErrorMapConfig = {
  [Code in ErrorCode]?: ErrorMapMessage | ErrorMapMessageBuilder<Code>
}
function makeErrorMap(config: ErrorMapConfig) {
  return (issue: z.ZodIssueOptionalMessage, ctx: z.ErrorMapCtx) => {
    const errorCode: ErrorCode = issue.code === 'invalid_type' && ctx.data === undefined ? 'required' : issue.code

    const messageOrBuilder = config[errorCode]
    const context = { ...ctx, ...issue, code: errorCode }

    const message: ErrorMapMessage | undefined =
      typeof messageOrBuilder === 'function'
        ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (messageOrBuilder as ErrorMapMessageBuilder<typeof context.code>)(context as any)
        : messageOrBuilder

    return message ? { message } : { message: ctx.defaultError }
  }
}
export const useZod = () => {
  const errorMap = makeErrorMap({
    required: { message: 'common.error.required', variables: {} },
    invalid_string: ({ validation }) => ({
      message: 'common.error.invalid_string',
      variables: { type: validation },
    }),
    too_small: ({ minimum }) => ({
      message: 'common.error.too_small',
      variables: { minimum },
    }),
  })

  return { errorMap }
}
