import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import AlertCircle from '~icons/epostbox/alert-circle'

import { toast } from '@epostbox/ui/sonner'
import { Module, Permission, Parser, ResourceCondition } from '@epostbox/user-data'

import { useUser } from '@composables/use-user'

interface Options {
  redirectToURL: string
}

export function useCan<T extends Module>(module: T) {
  const { user } = useUser()
  const router = useRouter()
  const { t } = useI18n()

  return (action: Permission<T>, conditions?: ResourceCondition<T>, options?: Options) => {
    if (!user.value) {
      if (options?.redirectToURL) {
        router.push('/')
        toast.error(t('common.not_allowed_to access_page'), {
          icon: AlertCircle,
        })
      }
      return
    }

    const canAccess = Boolean(
      Parser.can(
        {
          permission: action,
          resource: module,
          condition: { userId: user.value.id, workspaceId: user.value.workspaceId, ...conditions },
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (user.value.permissions || []) as any
      )
    )
    if (!canAccess && options?.redirectToURL) {
      router.push(options?.redirectToURL).then(() => {
        toast.error(t('common.not_allowed_to access_page'), {
          icon: AlertCircle,
        })
      })
    } else {
      return canAccess
    }
  }
}
