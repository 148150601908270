<script setup lang="ts">
import { ref, defineProps, watch, onMounted } from 'vue'

import { AppIconProps } from '.'

const props = defineProps<AppIconProps>()

const svgContent = ref<string>('')

const generateUniqueID = (id: string) => `${id}_${Math.random().toString(36).slice(2, 9)}`

const modifySvgContent = (svg: string) => {
  // gradients use a linearGradient element with a id to display the gradient
  // generate a unique id so when using multiple instances of the same icon, they don't have issues
  const parser = new DOMParser()
  const doc = parser.parseFromString(svg, 'image/svg+xml')
  const gradients = doc.querySelectorAll('linearGradient')

  // eslint-disable-next-line unicorn/no-array-for-each
  gradients.forEach(gradient => {
    const oldId = gradient.id
    const newId = generateUniqueID(oldId)
    gradient.id = newId

    // eslint-disable-next-line unicorn/no-array-for-each
    doc.querySelectorAll(`[fill="url(#${oldId})"]`).forEach(element => {
      element.setAttribute('fill', `url(#${newId})`)
    })
  })

  return new XMLSerializer().serializeToString(doc)
}

const loadSvg = async () => {
  try {
    const svgModule = await import(`../../assets/icons/${props.icon}.svg?raw`)
    svgContent.value = modifySvgContent(svgModule.default)
  } catch (error) {
    console.log(error)
  }
}

watch(() => props.icon, loadSvg, { immediate: true })

onMounted(() => loadSvg())
</script>

<template>
  <!-- eslint-disable vue/no-v-html -->
  <div
    class="[&>*]:h-full [&>*]:max-h-full [&>*]:w-full [&>*]:max-w-full [&>*]:object-contain"
    v-html="svgContent"
  ></div>
</template>
