<script lang="ts" setup>
import type { HTMLAttributes } from 'vue'

import { cn } from '../../utils'

const props = defineProps<{
  class?: HTMLAttributes['class']
}>()
</script>

<template>
  <li :class="cn('inline-flex items-center py-1', props.class)">
    <slot />
  </li>
</template>
