import { AuthAPIClient, InferBody, InferRequest, InferResponse } from '@epostbox/auth-api/client'

import { accessToken } from './retrieve-access-token'

const baseUrl = import.meta.env.DEV ? `${location.origin}/api/auth` : import.meta.env.VITE_AUTH_API_URL

const Auth = AuthAPIClient({ baseUrl, credentials: 'include' })

Auth.use({
  async onRequest({ request }) {
    if (accessToken) {
      request.headers.set('authorization', `Bearer ${accessToken}`)
    }
    return request
  },
})

export const AuthorizeURL = Auth.getUrl('/api/authorize')
export const LogoutURL = Auth.getUrl('/api/flow/logout')

export const TokenURL = Auth.getUrl('/api/token')
export const GetToken = Auth.post('/api/token')
// @ts-expect-error TODO: update types
export type GetToken = InferResponse<typeof GetToken>
// @ts-expect-error TODO: update types
export type GetTokenRequest = InferBody<typeof GetToken>

export const GetCurrentUser = Auth.get('/api/session/me')
export type CurrentUser = InferResponse<typeof GetCurrentUser>

export const AcceptInvitation = Auth.put('/api/workspaces/{workspaceId}/members/{userId}')
export type AcceptInvitation = InferResponse<typeof AcceptInvitation>
export type AcceptInvitationRequest = InferRequest<typeof AcceptInvitation>['params']['path']

export const AssignLogin = Auth.post('/api/workspaces/{workspaceId}/members/login')
export type AssignLogin = InferResponse<typeof AssignLogin>
export type AssignLoginRequest = Exclude<InferRequest<typeof AssignLogin>['body'], undefined>
export type AssignLoginRequestParam = InferRequest<typeof AssignLogin>['params']['path']

export const SetupAccountEmail = Auth.patch('/api/user/{workspaceId}/setup-account-email')
export type SetupAccountEmail = InferResponse<typeof SetupAccountEmail>
export type SetupAccountEmailRequest = Exclude<InferRequest<typeof SetupAccountEmail>['body'], undefined>
export type SetupAccountEmailRequestParam = InferRequest<typeof SetupAccountEmail>['params']['path']

export const UpdatePreferences = Auth.put('/api/me/preferences')
export type UpdatePreferencesRequest = Exclude<InferRequest<typeof UpdatePreferences>['body'], undefined>

export const CreateDomain = Auth.post('/api/domains/{workspaceId}') //.auth.api.domains[':workspaceId'].$post
export type CreateDomainRequest = Exclude<InferRequest<typeof CreateDomain>['body'], undefined>

export const ValidateDomain = Auth.put('/api/domains') //.auth.api.domains.$put
export type ValidateDomainRequest = Exclude<InferRequest<typeof ValidateDomain>['body'], undefined>

export const DeleteDomain = Auth.delete('/api/domains/{id}') //.auth.api.domains[':id'].$delete
export type DeleteDomainRequest = InferRequest<typeof DeleteDomain>['params']['path']

export const GetSAMLConfig = Auth.get('/api/sp/{workspaceId}') //.auth.api.sp[':workspaceId'].$get
export type GetSAMLConfig = InferResponse<typeof GetSAMLConfig>

export const SetSAMLConfig = Auth.post('/api/sp/{workspaceId}') //.auth.api.sp[':workspaceId'].$post
export type SetSAMLConfig = InferResponse<typeof SetSAMLConfig>
export type SetSAMLConfigRequest = Exclude<InferRequest<typeof SetSAMLConfig>['body'], undefined>

export const ToggleSAML = Auth.put('/api/sp/{workspaceId}/status') //.auth.api.sp[':workspaceId'].status.$put
export type ToggleSAML = InferResponse<typeof ToggleSAML>
export type ToggleSAMLRequest = Exclude<InferRequest<typeof ToggleSAML>['body'], undefined>

export const GetSCIMConfig = Auth.get('/api/scim/{workspaceId}') //.auth.api.scim[':workspaceId'].$get
export type GetSCIMConfig = InferResponse<typeof GetSCIMConfig>

export const GenerateSCIMToken = Auth.put('/api/scim/{workspaceId}') //.auth.api.scim[':workspaceId'].$put

export const GetWorkspace = Auth.get('/api/workspaces/{workspaceId}') //.auth.api.workspaces[':workspaceId'].$get
export type Workspace = InferResponse<typeof GetWorkspace>

export const AddWorkspaceMember = Auth.post('/api/workspaces/{workspaceId}/members') //.auth.api.workspaces[':workspaceId'].members.$post
export type AddWorkspaceMemberRequest = Exclude<InferRequest<typeof AddWorkspaceMember>['body'], undefined>

export const ListWorkspaceMembers = Auth.get('/api/workspaces/{workspaceId}/members') //.auth.api.workspaces[':workspaceId'].members.$get
export type WorkspaceMember = InferResponse<typeof ListWorkspaceMembers>['members'][number]
export type WorkspaceMemberID = WorkspaceMember['id']
export type ListWorkspaceMembersQuery = Exclude<InferRequest<typeof ListWorkspaceMembers>['params']['query'], undefined>

export const GetWorkspaceMember = Auth.get('/api/workspaces/{workspaceId}/members/{userId}') //.auth.api.workspaces[':workspaceId'].members[':userId'].$get

export const UpdateMemberPermissions = Auth.put('/api/workspaces/{workspaceId}/members/{userId}/permissions') //.auth.api.workspaces[':workspaceId'].members[':userId'].permissions.$put
export type UpdateMemberPermissionsRequest = Exclude<InferRequest<typeof UpdateMemberPermissions>['body'], undefined>

export const Register = Auth.post('/api/flow/register') //.auth.api.flow.register.$post
export type Register = InferResponse<typeof Register>
export type RegisterRequest = Exclude<InferRequest<typeof Register>['body'], undefined>

export const Login = Auth.post('/api/flow/login') //.auth.api.flow.login.$post
export type Login = InferResponse<typeof Login>
export type LoginRequest = Exclude<InferRequest<typeof Login>['body'], undefined>

export const LoginSAML = Auth.post('/api/flow/saml') //.auth.api.flow.saml.$post
export type LoginSAML = InferResponse<typeof LoginSAML>
export type LoginSAMLRequest = Exclude<InferRequest<typeof LoginSAML>['body'], undefined>

export const ForgotPassword = Auth.get('/api/flow/password') //.auth.api.flow.password.$get
export type ForgotPassword = InferResponse<typeof ForgotPassword>
export type ForgotPasswordRequest = InferRequest<typeof ForgotPassword>['params']['query']

export const ResetPassword = Auth.put('/api/flow/password') //.auth.api.flow.password.$put
export type ResetPassword = InferResponse<typeof ResetPassword>
export type ResetPasswordRequest = Exclude<InferRequest<typeof ResetPassword>['body'], undefined>
