import { defineAsyncComponent as def } from 'vue'

const ac = def(() => import('~icons/circle-flags/ac'))
const um = def(() => import('~icons/circle-flags/um'))
const eh = def(() => import('~icons/circle-flags/eh'))
const ad = def(() => import('~icons/circle-flags/ad'))
const bv = def(() => import('~icons/circle-flags/bv'))
const bq = def(() => import('~icons/circle-flags/bq'))
const ae = def(() => import('~icons/circle-flags/ae'))
const af = def(() => import('~icons/circle-flags/af'))
const as = def(() => import('~icons/circle-flags/as'))
const ag = def(() => import('~icons/circle-flags/ag'))
const ai = def(() => import('~icons/circle-flags/ai'))
const al = def(() => import('~icons/circle-flags/al'))
const am = def(() => import('~icons/circle-flags/am'))
const ao = def(() => import('~icons/circle-flags/ao'))
const aq = def(() => import('~icons/circle-flags/aq'))
const ar = def(() => import('~icons/circle-flags/ar'))
const at = def(() => import('~icons/circle-flags/at'))
const au = def(() => import('~icons/circle-flags/au'))
const aw = def(() => import('~icons/circle-flags/aw'))
const az = def(() => import('~icons/circle-flags/az'))
const ba = def(() => import('~icons/circle-flags/ba'))
const bb = def(() => import('~icons/circle-flags/bb'))
const bd = def(() => import('~icons/circle-flags/bd'))
const be = def(() => import('~icons/circle-flags/be'))
const bf = def(() => import('~icons/circle-flags/bf'))
const bg = def(() => import('~icons/circle-flags/bg'))
const bh = def(() => import('~icons/circle-flags/bh'))
const bi = def(() => import('~icons/circle-flags/bi'))
const bj = def(() => import('~icons/circle-flags/bj'))
const bl = def(() => import('~icons/circle-flags/bl'))
const bm = def(() => import('~icons/circle-flags/bm'))
const bn = def(() => import('~icons/circle-flags/bn'))
const bo = def(() => import('~icons/circle-flags/bo'))
const br = def(() => import('~icons/circle-flags/br'))
const bs = def(() => import('~icons/circle-flags/bs'))
const bt = def(() => import('~icons/circle-flags/bt'))
const bw = def(() => import('~icons/circle-flags/bw'))
const by = def(() => import('~icons/circle-flags/by'))
const bz = def(() => import('~icons/circle-flags/bz'))
const ca = def(() => import('~icons/circle-flags/ca'))
const cc = def(() => import('~icons/circle-flags/cc'))
const cd = def(() => import('~icons/circle-flags/cd'))
const cf = def(() => import('~icons/circle-flags/cf'))
const cg = def(() => import('~icons/circle-flags/cg'))
const ch = def(() => import('~icons/circle-flags/ch'))
const ci = def(() => import('~icons/circle-flags/ci'))
const ck = def(() => import('~icons/circle-flags/ck'))
const cl = def(() => import('~icons/circle-flags/cl'))
const cm = def(() => import('~icons/circle-flags/cm'))
const cn = def(() => import('~icons/circle-flags/cn'))
const co = def(() => import('~icons/circle-flags/co'))
const cr = def(() => import('~icons/circle-flags/cr'))
const cu = def(() => import('~icons/circle-flags/cu'))
const cv = def(() => import('~icons/circle-flags/cv'))
const cw = def(() => import('~icons/circle-flags/cw'))
const cx = def(() => import('~icons/circle-flags/cx'))
const cy = def(() => import('~icons/circle-flags/cy'))
const cz = def(() => import('~icons/circle-flags/cz'))
const de = def(() => import('~icons/circle-flags/de'))
const dj = def(() => import('~icons/circle-flags/dj'))
const dk = def(() => import('~icons/circle-flags/dk'))
const dz = def(() => import('~icons/circle-flags/dz'))
const dm = def(() => import('~icons/circle-flags/dm'))
const dominicanRepublic = def(() => import('~icons/circle-flags/do'))
const ec = def(() => import('~icons/circle-flags/ec'))
const ee = def(() => import('~icons/circle-flags/ee'))
const eg = def(() => import('~icons/circle-flags/eg'))
const er = def(() => import('~icons/circle-flags/er'))
const es = def(() => import('~icons/circle-flags/es'))
const et = def(() => import('~icons/circle-flags/et'))
const fi = def(() => import('~icons/circle-flags/fi'))
const fj = def(() => import('~icons/circle-flags/fj'))
const fk = def(() => import('~icons/circle-flags/fk'))
const fm = def(() => import('~icons/circle-flags/fm'))
const fo = def(() => import('~icons/circle-flags/fo'))
const fr = def(() => import('~icons/circle-flags/fr'))
const ga = def(() => import('~icons/circle-flags/ga'))
const gb = def(() => import('~icons/circle-flags/gb'))
const gd = def(() => import('~icons/circle-flags/gd'))
const ge = def(() => import('~icons/circle-flags/ge'))
const gf = def(() => import('~icons/circle-flags/gf'))
const gg = def(() => import('~icons/circle-flags/gg'))
const gh = def(() => import('~icons/circle-flags/gh'))
const gi = def(() => import('~icons/circle-flags/gi'))
const gl = def(() => import('~icons/circle-flags/gl'))
const gm = def(() => import('~icons/circle-flags/gm'))
const gn = def(() => import('~icons/circle-flags/gn'))
const gp = def(() => import('~icons/circle-flags/gp'))
const gq = def(() => import('~icons/circle-flags/gq'))
const gr = def(() => import('~icons/circle-flags/gr'))
const gs = def(() => import('~icons/circle-flags/gs'))
const gt = def(() => import('~icons/circle-flags/gt'))
const gu = def(() => import('~icons/circle-flags/gu'))
const gw = def(() => import('~icons/circle-flags/gw'))
const gy = def(() => import('~icons/circle-flags/gy'))
const hk = def(() => import('~icons/circle-flags/hk'))
const hm = def(() => import('~icons/circle-flags/hm'))
const hn = def(() => import('~icons/circle-flags/hn'))
const hr = def(() => import('~icons/circle-flags/hr'))
const ht = def(() => import('~icons/circle-flags/ht'))
const hu = def(() => import('~icons/circle-flags/hu'))
const id = def(() => import('~icons/circle-flags/id'))
const ie = def(() => import('~icons/circle-flags/ie'))
const il = def(() => import('~icons/circle-flags/il'))
const im = def(() => import('~icons/circle-flags/im'))
const india = def(() => import('~icons/circle-flags/in'))
const iq = def(() => import('~icons/circle-flags/iq'))
const ir = def(() => import('~icons/circle-flags/ir'))
const is = def(() => import('~icons/circle-flags/is'))
const io = def(() => import('~icons/circle-flags/io'))
const it = def(() => import('~icons/circle-flags/it'))
const je = def(() => import('~icons/circle-flags/je'))
const jm = def(() => import('~icons/circle-flags/jm'))
const jo = def(() => import('~icons/circle-flags/jo'))
const jp = def(() => import('~icons/circle-flags/jp'))
const ke = def(() => import('~icons/circle-flags/ke'))
const kg = def(() => import('~icons/circle-flags/kg'))
const kh = def(() => import('~icons/circle-flags/kh'))
const ki = def(() => import('~icons/circle-flags/ki'))
const km = def(() => import('~icons/circle-flags/km'))
const kn = def(() => import('~icons/circle-flags/kn'))
const kp = def(() => import('~icons/circle-flags/kp'))
const kr = def(() => import('~icons/circle-flags/kr'))
const kw = def(() => import('~icons/circle-flags/kw'))
const ky = def(() => import('~icons/circle-flags/ky'))
const kz = def(() => import('~icons/circle-flags/kz'))
const la = def(() => import('~icons/circle-flags/la'))
const lb = def(() => import('~icons/circle-flags/lb'))
const lc = def(() => import('~icons/circle-flags/lc'))
const li = def(() => import('~icons/circle-flags/li'))
const lk = def(() => import('~icons/circle-flags/lk'))
const lr = def(() => import('~icons/circle-flags/lr'))
const ls = def(() => import('~icons/circle-flags/ls'))
const lt = def(() => import('~icons/circle-flags/lt'))
const lu = def(() => import('~icons/circle-flags/lu'))
const lv = def(() => import('~icons/circle-flags/lv'))
const ly = def(() => import('~icons/circle-flags/ly'))
const ma = def(() => import('~icons/circle-flags/ma'))
const mc = def(() => import('~icons/circle-flags/mc'))
const md = def(() => import('~icons/circle-flags/md'))
const me = def(() => import('~icons/circle-flags/me'))
const mf = def(() => import('~icons/circle-flags/mf'))
const mg = def(() => import('~icons/circle-flags/mg'))
const mh = def(() => import('~icons/circle-flags/mh'))
const ml = def(() => import('~icons/circle-flags/ml'))
const mm = def(() => import('~icons/circle-flags/mm'))
const mn = def(() => import('~icons/circle-flags/mn'))
const mo = def(() => import('~icons/circle-flags/mo'))
const mp = def(() => import('~icons/circle-flags/mp'))
const mq = def(() => import('~icons/circle-flags/mq'))
const mr = def(() => import('~icons/circle-flags/mr'))
const ms = def(() => import('~icons/circle-flags/ms'))
const mt = def(() => import('~icons/circle-flags/mt'))
const mu = def(() => import('~icons/circle-flags/mu'))
const mv = def(() => import('~icons/circle-flags/mv'))
const mw = def(() => import('~icons/circle-flags/mw'))
const mx = def(() => import('~icons/circle-flags/mx'))
const my = def(() => import('~icons/circle-flags/my'))
const mk = def(() => import('~icons/circle-flags/mk'))
const mz = def(() => import('~icons/circle-flags/mz'))
const na = def(() => import('~icons/circle-flags/na'))
const nc = def(() => import('~icons/circle-flags/nc'))
const ne = def(() => import('~icons/circle-flags/ne'))
const an = def(() => import('~icons/circle-flags/an'))
const vc = def(() => import('~icons/circle-flags/vc'))
const vg = def(() => import('~icons/circle-flags/vg'))
const vi = def(() => import('~icons/circle-flags/vi'))
const ye = def(() => import('~icons/circle-flags/ye'))
const zm = def(() => import('~icons/circle-flags/zm'))
const zw = def(() => import('~icons/circle-flags/zw'))
const ax = def(() => import('~icons/circle-flags/ax'))
const nf = def(() => import('~icons/circle-flags/nf'))
const ng = def(() => import('~icons/circle-flags/ng'))
const ni = def(() => import('~icons/circle-flags/ni'))
const nl = def(() => import('~icons/circle-flags/nl'))
const no = def(() => import('~icons/circle-flags/no'))
const np = def(() => import('~icons/circle-flags/np'))
const nr = def(() => import('~icons/circle-flags/nr'))
const nu = def(() => import('~icons/circle-flags/nu'))
const nz = def(() => import('~icons/circle-flags/nz'))
const om = def(() => import('~icons/circle-flags/om'))
const pa = def(() => import('~icons/circle-flags/pa'))
const pe = def(() => import('~icons/circle-flags/pe'))
const pf = def(() => import('~icons/circle-flags/pf'))
const pg = def(() => import('~icons/circle-flags/pg'))
const ph = def(() => import('~icons/circle-flags/ph'))
const pk = def(() => import('~icons/circle-flags/pk'))
const pl = def(() => import('~icons/circle-flags/pl'))
const pm = def(() => import('~icons/circle-flags/pm'))
const pn = def(() => import('~icons/circle-flags/pn'))
const pr = def(() => import('~icons/circle-flags/pr'))
const ps = def(() => import('~icons/circle-flags/ps'))
const pt = def(() => import('~icons/circle-flags/pt'))
const pw = def(() => import('~icons/circle-flags/pw'))
const py = def(() => import('~icons/circle-flags/py'))
const qa = def(() => import('~icons/circle-flags/qa'))
const re = def(() => import('~icons/circle-flags/re'))
const ro = def(() => import('~icons/circle-flags/ro'))
const rs = def(() => import('~icons/circle-flags/rs'))
const ru = def(() => import('~icons/circle-flags/ru'))
const rw = def(() => import('~icons/circle-flags/rw'))
const sa = def(() => import('~icons/circle-flags/sa'))
const sb = def(() => import('~icons/circle-flags/sb'))
const sc = def(() => import('~icons/circle-flags/sc'))
const sd = def(() => import('~icons/circle-flags/sd'))
const se = def(() => import('~icons/circle-flags/se'))
const sg = def(() => import('~icons/circle-flags/sg'))
const sh = def(() => import('~icons/circle-flags/sh'))
const si = def(() => import('~icons/circle-flags/si'))
const sj = def(() => import('~icons/circle-flags/sj'))
const sk = def(() => import('~icons/circle-flags/sk'))
const sl = def(() => import('~icons/circle-flags/sl'))
const sm = def(() => import('~icons/circle-flags/sm'))
const sn = def(() => import('~icons/circle-flags/sn'))
const so = def(() => import('~icons/circle-flags/so'))
const sr = def(() => import('~icons/circle-flags/sr'))
const ss = def(() => import('~icons/circle-flags/ss'))
const st = def(() => import('~icons/circle-flags/st'))
const sv = def(() => import('~icons/circle-flags/sv'))
const sx = def(() => import('~icons/circle-flags/sx'))
const sy = def(() => import('~icons/circle-flags/sy'))
const sz = def(() => import('~icons/circle-flags/sz'))
const tc = def(() => import('~icons/circle-flags/tc'))
const td = def(() => import('~icons/circle-flags/td'))
const tf = def(() => import('~icons/circle-flags/tf'))
const tg = def(() => import('~icons/circle-flags/tg'))
const th = def(() => import('~icons/circle-flags/th'))
const tj = def(() => import('~icons/circle-flags/tj'))
const tk = def(() => import('~icons/circle-flags/tk'))
const tl = def(() => import('~icons/circle-flags/tl'))
const tm = def(() => import('~icons/circle-flags/tm'))
const tn = def(() => import('~icons/circle-flags/tn'))
const to = def(() => import('~icons/circle-flags/to'))
const tr = def(() => import('~icons/circle-flags/tr'))
const tt = def(() => import('~icons/circle-flags/tt'))
const tv = def(() => import('~icons/circle-flags/tv'))
const tw = def(() => import('~icons/circle-flags/tw'))
const tz = def(() => import('~icons/circle-flags/tz'))
const ua = def(() => import('~icons/circle-flags/ua'))
const ug = def(() => import('~icons/circle-flags/ug'))
const us = def(() => import('~icons/circle-flags/us'))
const uy = def(() => import('~icons/circle-flags/uy'))
const uz = def(() => import('~icons/circle-flags/uz'))
const va = def(() => import('~icons/circle-flags/va'))
const ve = def(() => import('~icons/circle-flags/ve'))
const vn = def(() => import('~icons/circle-flags/vn'))
const vu = def(() => import('~icons/circle-flags/vu'))
const wf = def(() => import('~icons/circle-flags/wf'))
const ws = def(() => import('~icons/circle-flags/ws'))
const xk = def(() => import('~icons/circle-flags/xk'))
const yt = def(() => import('~icons/circle-flags/yt'))
const za = def(() => import('~icons/circle-flags/za'))

const countries = [
  {
    label: 'common.countries.AF',
    country_name: 'Afghanistan',
    dial_code: '+93',
    value: 'AF',
    icon: af,
  },
  {
    label: 'common.countries.AL',
    country_name: 'Albania',
    dial_code: '+355',
    value: 'AL',
    icon: al,
  },
  {
    label: 'common.countries.DZ',
    country_name: 'Algeria',
    dial_code: '+213',
    value: 'DZ',
    icon: dz,
  },
  {
    label: 'common.countries.AS',
    country_name: 'American Samoa',
    dial_code: '+1 684',
    value: 'AS',
    icon: as,
  },
  {
    label: 'common.countries.AD',
    country_name: 'Andorra',
    dial_code: '+376',
    value: 'AD',
    icon: ad,
  },
  {
    label: 'common.countries.AO',
    country_name: 'Angola',
    dial_code: '+244',
    value: 'AO',
    icon: ao,
  },
  {
    label: 'common.countries.AI',
    country_name: 'Anguilla',
    dial_code: '+1 264',
    value: 'AI',
    icon: ai,
  },
  {
    label: 'common.countries.AQ',
    country_name: 'Antarctica',
    dial_code: '+672',
    value: 'AQ',
    icon: aq,
  },
  {
    label: 'common.countries.AG',
    country_name: 'Antigua and Barbuda',
    dial_code: '+1 268',
    value: 'AG',
    icon: ag,
  },
  {
    label: 'common.countries.AR',
    country_name: 'Argentina',
    dial_code: '+54',
    value: 'AR',
    icon: ar,
  },
  {
    label: 'common.countries.AM',
    country_name: 'Armenia',
    dial_code: '+374',
    value: 'AM',
    icon: am,
  },
  {
    label: 'common.countries.AW',
    country_name: 'Aruba',
    dial_code: '+297',
    value: 'AW',
    icon: aw,
  },
  {
    label: 'common.countries.AC',
    country_name: 'Ascension Island',
    dial_code: '+247',
    value: 'AC',
    icon: ac,
  },
  {
    label: 'common.countries.AU',
    country_name: 'Australia',
    dial_code: '+61',
    value: 'AU',
    icon: au,
  },
  {
    label: 'common.countries.AT',
    country_name: 'Austria',
    dial_code: '+43',
    value: 'AT',
    icon: at,
  },
  {
    label: 'common.countries.AZ',
    country_name: 'Azerbaijan',
    dial_code: '+994',
    value: 'AZ',
    icon: az,
  },
  {
    label: 'common.countries.BS',
    country_name: 'Bahamas',
    dial_code: '+1 242',
    value: 'BS',
    icon: bs,
  },
  {
    label: 'common.countries.BH',
    country_name: 'Bahrain',
    dial_code: '+973',
    value: 'BH',
    icon: bh,
  },
  {
    label: 'common.countries.BD',
    country_name: 'Bangladesh',
    dial_code: '+880',
    value: 'BD',
    icon: bd,
  },
  {
    label: 'common.countries.BB',
    country_name: 'Barbados',
    dial_code: '+1 246',
    value: 'BB',
    icon: bb,
  },
  {
    label: 'common.countries.BY',
    country_name: 'Belarus',
    dial_code: '+375',
    value: 'BY',
    icon: by,
  },
  {
    label: 'common.countries.BE',
    country_name: 'Belgium',
    dial_code: '+32',
    value: 'BE',
    icon: be,
  },
  {
    label: 'common.countries.BZ',
    country_name: 'Belize',
    dial_code: '+501',
    value: 'BZ',
    icon: bz,
  },
  {
    label: 'common.countries.BJ',
    country_name: 'Benin',
    dial_code: '+229',
    value: 'BJ',
    icon: bj,
  },
  {
    label: 'common.countries.BM',
    country_name: 'Bermuda',
    dial_code: '+1 441',
    value: 'BM',
    icon: bm,
  },
  {
    label: 'common.countries.BT',
    country_name: 'Bhutan',
    dial_code: '+975',
    value: 'BT',
    icon: bt,
  },
  {
    label: 'common.countries.BO',
    country_name: 'Bolivia, Plurinational State of',
    dial_code: '+591',
    value: 'BO',
    icon: bo,
  },
  {
    label: 'common.countries.BQ',
    country_name: 'Bonaire, Sint Eustatius and Saba',
    dial_code: '+599',
    value: 'BQ',
    icon: bq,
  },
  {
    label: 'common.countries.BA',
    country_name: 'Bosnia and Herzegovina',
    dial_code: '+387',
    value: 'BA',
    icon: ba,
  },
  {
    label: 'common.countries.BW',
    country_name: 'Botswana',
    dial_code: '+267',
    value: 'BW',
    icon: bw,
  },
  {
    label: 'common.countries.BV',
    country_name: 'Bouvet Island',
    dial_code: '+47',
    value: 'BV',
    icon: bv,
  },
  {
    label: 'common.countries.BR',
    country_name: 'Brazil',
    dial_code: '+55',
    value: 'BR',
    icon: br,
  },
  {
    label: 'common.countries.IO',
    country_name: 'British Indian Ocean Territory',
    dial_code: '+246',
    value: 'IO',
    icon: io,
  },
  {
    label: 'common.countries.BN',
    country_name: 'Brunei Darussalam',
    dial_code: '+673',
    value: 'BN',
    icon: bn,
  },
  {
    label: 'common.countries.BG',
    country_name: 'Bulgaria',
    dial_code: '+359',
    value: 'BG',
    icon: bg,
  },
  {
    label: 'common.countries.BF',
    country_name: 'Burkina Faso',
    dial_code: '+226',
    value: 'BF',
    icon: bf,
  },
  {
    label: 'common.countries.BI',
    country_name: 'Burundi',
    dial_code: '+257',
    value: 'BI',
    icon: bi,
  },
  {
    label: 'common.countries.CV',
    country_name: 'Cabo Verde',
    dial_code: '+238',
    value: 'CV',
    icon: cv,
  },
  {
    label: 'common.countries.KH',
    country_name: 'Cambodia',
    dial_code: '+855',
    value: 'KH',
    icon: kh,
  },
  {
    label: 'common.countries.CM',
    country_name: 'Cameroon',
    dial_code: '+237',
    value: 'CM',
    icon: cm,
  },
  {
    label: 'common.countries.CA',
    country_name: 'Canada',
    dial_code: '+1',
    value: 'CA',
    icon: ca,
  },
  {
    label: 'common.countries.KY',
    country_name: 'Cayman Islands',
    dial_code: '+ 345',
    value: 'KY',
    icon: ky,
  },
  {
    label: 'common.countries.CF',
    country_name: 'Central African Republic',
    dial_code: '+236',
    value: 'CF',
    icon: cf,
  },
  {
    label: 'common.countries.TD',
    country_name: 'Chad',
    dial_code: '+235',
    value: 'TD',
    icon: td,
  },
  {
    label: 'common.countries.CL',
    country_name: 'Chile',
    dial_code: '+56',
    value: 'CL',
    icon: cl,
  },
  {
    label: 'common.countries.CN',
    country_name: 'China',
    dial_code: '+86',
    value: 'CN',
    icon: cn,
  },
  {
    label: 'common.countries.CX',
    country_name: 'Christmas Island',
    dial_code: '+61',
    value: 'CX',
    icon: cx,
  },
  {
    label: 'common.countries.CC',
    country_name: 'Cocos (Keeling) Islands',
    dial_code: '+61',
    value: 'CC',
    icon: cc,
  },
  {
    label: 'common.countries.CO',
    country_name: 'Colombia',
    dial_code: '+57',
    value: 'CO',
    icon: co,
  },
  {
    label: 'common.countries.KM',
    country_name: 'Comoros',
    dial_code: '+269',
    value: 'KM',
    icon: km,
  },
  {
    label: 'common.countries.CG',
    country_name: 'Congo',
    dial_code: '+242',
    value: 'CG',
    icon: cg,
  },
  {
    label: 'common.countries.CD',
    country_name: 'Congo, The Democratic Republic of the',
    dial_code: '+243',
    value: 'CD',
    icon: cd,
  },
  {
    label: 'common.countries.CK',
    country_name: 'Cook Islands',
    dial_code: '+682',
    value: 'CK',
    icon: ck,
  },
  {
    label: 'common.countries.CR',
    country_name: 'Costa Rica',
    dial_code: '+506',
    value: 'CR',
    icon: cr,
  },
  {
    label: 'common.countries.HR',
    country_name: 'Croatia',
    dial_code: '+385',
    value: 'HR',
    icon: hr,
  },
  {
    label: 'common.countries.CU',
    country_name: 'Cuba',
    dial_code: '+53',
    value: 'CU',
    icon: cu,
  },
  {
    label: 'common.countries.CW',
    country_name: 'Curaçao',
    dial_code: '+599',
    value: 'CW',
    icon: cw,
  },
  {
    label: 'common.countries.CY',
    country_name: 'Cyprus',
    dial_code: '+357',
    value: 'CY',
    icon: cy,
  },
  {
    label: 'common.countries.CZ',
    country_name: 'Czech Republic',
    dial_code: '+420',
    value: 'CZ',
    icon: cz,
  },
  {
    label: 'common.countries.CI',
    country_name: "Côte d'Ivoire",
    dial_code: '+225',
    value: 'CI',
    icon: ci,
  },
  {
    label: 'common.countries.DK',
    country_name: 'Denmark',
    dial_code: '+45',
    value: 'DK',
    icon: dk,
  },
  {
    label: 'common.countries.DJ',
    country_name: 'Djibouti',
    dial_code: '+253',
    value: 'DJ',
    icon: dj,
  },
  {
    label: 'common.countries.DM',
    country_name: 'Dominica',
    dial_code: '+1 767',
    value: 'DM',
    icon: dm,
  },
  {
    label: 'common.countries.DO',
    country_name: 'Dominican Republic',
    dial_code: '+1 849',
    value: 'DO',
    icon: dominicanRepublic,
  },
  {
    label: 'common.countries.EC',
    country_name: 'Ecuador',
    dial_code: '+593',
    value: 'EC',
    icon: ec,
  },
  {
    label: 'common.countries.EG',
    country_name: 'Egypt',
    dial_code: '+20',
    value: 'EG',
    icon: eg,
  },
  {
    label: 'common.countries.SV',
    country_name: 'El Salvador',
    dial_code: '+503',
    value: 'SV',
    icon: sv,
  },
  {
    label: 'common.countries.GQ',
    country_name: 'Equatorial Guinea',
    dial_code: '+240',
    value: 'GQ',
    icon: gq,
  },
  {
    label: 'common.countries.ER',
    country_name: 'Eritrea',
    dial_code: '+291',
    value: 'ER',
    icon: er,
  },
  {
    label: 'common.countries.EE',
    country_name: 'Estonia',
    dial_code: '+372',
    value: 'EE',
    icon: ee,
  },
  {
    label: 'common.countries.SZ',
    country_name: 'Eswatini',
    dial_code: '+268',
    value: 'SZ',
    icon: sz,
  },
  {
    label: 'common.countries.ET',
    country_name: 'Ethiopia',
    dial_code: '+251',
    value: 'ET',
    icon: et,
  },
  {
    label: 'common.countries.FK',
    country_name: 'Falkland Islands (Malvinas)',
    dial_code: '+500',
    value: 'FK',
    icon: fk,
  },
  {
    label: 'common.countries.FO',
    country_name: 'Faroe Islands',
    dial_code: '+298',
    value: 'FO',
    icon: fo,
  },
  {
    label: 'common.countries.FJ',
    country_name: 'Fiji',
    dial_code: '+679',
    value: 'FJ',
    icon: fj,
  },
  {
    label: 'common.countries.FI',
    country_name: 'Finland',
    dial_code: '+358',
    value: 'FI',
    icon: fi,
  },
  {
    label: 'common.countries.FR',
    country_name: 'France',
    dial_code: '+33',
    value: 'FR',
    icon: fr,
  },
  {
    label: 'common.countries.GF',
    country_name: 'French Guiana',
    dial_code: '+594',
    value: 'GF',
    icon: gf,
  },
  {
    label: 'common.countries.PF',
    country_name: 'French Polynesia',
    dial_code: '+689',
    value: 'PF',
    icon: pf,
  },
  {
    label: 'common.countries.TF',
    country_name: 'French Southern Territories',
    dial_code: '+262',
    value: 'TF',
    icon: tf,
  },
  {
    label: 'common.countries.GA',
    country_name: 'Gabon',
    dial_code: '+241',
    value: 'GA',
    icon: ga,
  },
  {
    label: 'common.countries.GM',
    country_name: 'Gambia',
    dial_code: '+220',
    value: 'GM',
    icon: gm,
  },
  {
    label: 'common.countries.GE',
    country_name: 'Georgia',
    dial_code: '+995',
    value: 'GE',
    icon: ge,
  },
  {
    label: 'common.countries.DE',
    country_name: 'Germany',
    dial_code: '+49',
    value: 'DE',
    icon: de,
  },
  {
    label: 'common.countries.GH',
    country_name: 'Ghana',
    dial_code: '+233',
    value: 'GH',
    icon: gh,
  },
  {
    label: 'common.countries.GI',
    country_name: 'Gibraltar',
    dial_code: '+350',
    value: 'GI',
    icon: gi,
  },
  {
    label: 'common.countries.GR',
    country_name: 'Greece',
    dial_code: '+30',
    value: 'GR',
    icon: gr,
  },
  {
    label: 'common.countries.GL',
    country_name: 'Greenland',
    dial_code: '+299',
    value: 'GL',
    icon: gl,
  },
  {
    label: 'common.countries.GD',
    country_name: 'Grenada',
    dial_code: '+1 473',
    value: 'GD',
    icon: gd,
  },
  {
    label: 'common.countries.GP',
    country_name: 'Guadeloupe',
    dial_code: '+590',
    value: 'GP',
    icon: gp,
  },
  {
    label: 'common.countries.GU',
    country_name: 'Guam',
    dial_code: '+1 671',
    value: 'GU',
    icon: gu,
  },
  {
    label: 'common.countries.GT',
    country_name: 'Guatemala',
    dial_code: '+502',
    value: 'GT',
    icon: gt,
  },
  {
    label: 'common.countries.GG',
    country_name: 'Guernsey',
    dial_code: '+44',
    value: 'GG',
    icon: gg,
  },
  {
    label: 'common.countries.GN',
    country_name: 'Guinea',
    dial_code: '+224',
    value: 'GN',
    icon: gn,
  },
  {
    label: 'common.countries.GW',
    country_name: 'Guinea-Bissau',
    dial_code: '+245',
    value: 'GW',
    icon: gw,
  },
  {
    label: 'common.countries.GY',
    country_name: 'Guyana',
    dial_code: '+595',
    value: 'GY',
    icon: gy,
  },
  {
    label: 'common.countries.HT',
    country_name: 'Haiti',
    dial_code: '+509',
    value: 'HT',
    icon: ht,
  },
  {
    label: 'common.countries.HM',
    country_name: 'Heard Island and McDonald Islands',
    dial_code: '+672',
    value: 'HM',
    icon: hm,
  },
  {
    label: 'common.countries.VA',
    country_name: 'Holy See',
    dial_code: '+379',
    value: 'VA',
    icon: va,
  },
  {
    label: 'common.countries.HN',
    country_name: 'Honduras',
    dial_code: '+504',
    value: 'HN',
    icon: hn,
  },
  {
    label: 'common.countries.HK',
    country_name: 'Hong Kong',
    dial_code: '+852',
    value: 'HK',
    icon: hk,
  },
  {
    label: 'common.countries.HU',
    country_name: 'Hungary',
    dial_code: '+36',
    value: 'HU',
    icon: hu,
  },
  {
    label: 'common.countries.IS',
    country_name: 'Iceland',
    dial_code: '+354',
    value: 'IS',
    icon: is,
  },
  {
    label: 'common.countries.IN',
    country_name: 'India',
    dial_code: '+91',
    value: 'IN',
    icon: india,
  },
  {
    label: 'common.countries.ID',
    country_name: 'Indonesia',
    dial_code: '+62',
    value: 'ID',
    icon: id,
  },
  {
    label: 'common.countries.IR',
    country_name: 'Iran, Islamic Republic of',
    dial_code: '+98',
    value: 'IR',
    icon: ir,
  },
  {
    label: 'common.countries.IQ',
    country_name: 'Iraq',
    dial_code: '+964',
    value: 'IQ',
    icon: iq,
  },
  {
    label: 'common.countries.IE',
    country_name: 'Ireland',
    dial_code: '+353',
    value: 'IE',
    icon: ie,
  },
  {
    label: 'common.countries.IM',
    country_name: 'Isle of Man',
    dial_code: '+44',
    value: 'IM',
    icon: im,
  },
  {
    label: 'common.countries.IL',
    country_name: 'Israel',
    dial_code: '+972',
    value: 'IL',
    icon: il,
  },
  {
    label: 'common.countries.IT',
    country_name: 'Italy',
    dial_code: '+39',
    value: 'IT',
    icon: it,
  },
  {
    label: 'common.countries.JM',
    country_name: 'Jamaica',
    dial_code: '+1 876',
    value: 'JM',
    icon: jm,
  },
  {
    label: 'common.countries.JP',
    country_name: 'Japan',
    dial_code: '+81',
    value: 'JP',
    icon: jp,
  },
  {
    label: 'common.countries.JE',
    country_name: 'Jersey',
    dial_code: '+44',
    value: 'JE',
    icon: je,
  },
  {
    label: 'common.countries.JO',
    country_name: 'Jordan',
    dial_code: '+962',
    value: 'JO',
    icon: jo,
  },
  {
    label: 'common.countries.KZ',
    country_name: 'Kazakhstan',
    dial_code: '+7 7',
    value: 'KZ',
    icon: kz,
  },
  {
    label: 'common.countries.KE',
    country_name: 'Kenya',
    dial_code: '+254',
    value: 'KE',
    icon: ke,
  },
  {
    label: 'common.countries.KI',
    country_name: 'Kiribati',
    dial_code: '+686',
    value: 'KI',
    icon: ki,
  },
  {
    label: 'common.countries.KP',
    country_name: "Korea, Democratic People's Republic of",
    dial_code: '+850',
    value: 'KP',
    icon: kp,
  },
  {
    label: 'common.countries.KR',
    country_name: 'Korea, Republic of',
    dial_code: '+82',
    value: 'KR',
    icon: kr,
  },
  {
    label: 'common.countries.XK',
    country_name: 'Kosovo',
    dial_code: '+383',
    value: 'XK',
    icon: xk,
  },
  {
    label: 'common.countries.KW',
    country_name: 'Kuwait',
    dial_code: '+965',
    value: 'KW',
    icon: kw,
  },
  {
    label: 'common.countries.KG',
    country_name: 'Kyrgyzstan',
    dial_code: '+996',
    value: 'KG',
    icon: kg,
  },
  {
    label: 'common.countries.LA',
    country_name: "Lao People's Democratic Republic",
    dial_code: '+856',
    value: 'LA',
    icon: la,
  },
  {
    label: 'common.countries.LV',
    country_name: 'Latvia',
    dial_code: '+371',
    value: 'LV',
    icon: lv,
  },
  {
    label: 'common.countries.LB',
    country_name: 'Lebanon',
    dial_code: '+961',
    value: 'LB',
    icon: lb,
  },
  {
    label: 'common.countries.LS',
    country_name: 'Lesotho',
    dial_code: '+266',
    value: 'LS',
    icon: ls,
  },
  {
    label: 'common.countries.LR',
    country_name: 'Liberia',
    dial_code: '+231',
    value: 'LR',
    icon: lr,
  },
  {
    label: 'common.countries.LY',
    country_name: 'Libya',
    dial_code: '+218',
    value: 'LY',
    icon: ly,
  },
  {
    label: 'common.countries.LI',
    country_name: 'Liechtenstein',
    dial_code: '+423',
    value: 'LI',
    icon: li,
  },
  {
    label: 'common.countries.LT',
    country_name: 'Lithuania',
    dial_code: '+370',
    value: 'LT',
    icon: lt,
  },
  {
    label: 'common.countries.LU',
    country_name: 'Luxembourg',
    dial_code: '+352',
    value: 'LU',
    icon: lu,
  },
  {
    label: 'common.countries.MO',
    country_name: 'Macao',
    dial_code: '+853',
    value: 'MO',
    icon: mo,
  },
  {
    label: 'common.countries.MG',
    country_name: 'Madagascar',
    dial_code: '+261',
    value: 'MG',
    icon: mg,
  },
  {
    label: 'common.countries.MW',
    country_name: 'Malawi',
    dial_code: '+265',
    value: 'MW',
    icon: mw,
  },
  {
    label: 'common.countries.MY',
    country_name: 'Malaysia',
    dial_code: '+60',
    value: 'MY',
    icon: my,
  },
  {
    label: 'common.countries.MV',
    country_name: 'Maldives',
    dial_code: '+960',
    value: 'MV',
    icon: mv,
  },
  {
    label: 'common.countries.ML',
    country_name: 'Mali',
    dial_code: '+223',
    value: 'ML',
    icon: ml,
  },
  {
    label: 'common.countries.MT',
    country_name: 'Malta',
    dial_code: '+356',
    value: 'MT',
    icon: mt,
  },
  {
    label: 'common.countries.MH',
    country_name: 'Marshall Islands',
    dial_code: '+692',
    value: 'MH',
    icon: mh,
  },
  {
    label: 'common.countries.MQ',
    country_name: 'Martinique',
    dial_code: '+596',
    value: 'MQ',
    icon: mq,
  },
  {
    label: 'common.countries.MR',
    country_name: 'Mauritania',
    dial_code: '+222',
    value: 'MR',
    icon: mr,
  },
  {
    label: 'common.countries.MU',
    country_name: 'Mauritius',
    dial_code: '+230',
    value: 'MU',
    icon: mu,
  },
  {
    label: 'common.countries.YT',
    country_name: 'Mayotte',
    dial_code: '+262',
    value: 'YT',
    icon: yt,
  },
  {
    label: 'common.countries.MX',
    country_name: 'Mexico',
    dial_code: '+52',
    value: 'MX',
    icon: mx,
  },
  {
    label: 'common.countries.FM',
    country_name: 'Micronesia, Federated States of',
    dial_code: '+691',
    value: 'FM',
    icon: fm,
  },
  {
    label: 'common.countries.MD',
    country_name: 'Moldova, Republic of',
    dial_code: '+373',
    value: 'MD',
    icon: md,
  },
  {
    label: 'common.countries.MC',
    country_name: 'Monaco',
    dial_code: '+377',
    value: 'MC',
    icon: mc,
  },
  {
    label: 'common.countries.MN',
    country_name: 'Mongolia',
    dial_code: '+976',
    value: 'MN',
    icon: mn,
  },
  {
    label: 'common.countries.ME',
    country_name: 'Montenegro',
    dial_code: '+382',
    value: 'ME',
    icon: me,
  },
  {
    label: 'common.countries.MS',
    country_name: 'Montserrat',
    dial_code: '+1 664',
    value: 'MS',
    icon: ms,
  },
  {
    label: 'common.countries.MA',
    country_name: 'Morocco',
    dial_code: '+212',
    value: 'MA',
    icon: ma,
  },
  {
    label: 'common.countries.MZ',
    country_name: 'Mozambique',
    dial_code: '+258',
    value: 'MZ',
    icon: mz,
  },
  {
    label: 'common.countries.MM',
    country_name: 'Myanmar',
    dial_code: '+95',
    value: 'MM',
    icon: mm,
  },
  {
    label: 'common.countries.NA',
    country_name: 'Namibia',
    dial_code: '+264',
    value: 'NA',
    icon: na,
  },
  {
    label: 'common.countries.NR',
    country_name: 'Nauru',
    dial_code: '+674',
    value: 'NR',
    icon: nr,
  },
  {
    label: 'common.countries.NP',
    country_name: 'Nepal',
    dial_code: '+977',
    value: 'NP',
    icon: np,
  },
  {
    label: 'common.countries.NL',
    country_name: 'Netherlands',
    dial_code: '+31',
    value: 'NL',
    icon: nl,
  },
  {
    label: 'common.countries.AN',
    country_name: 'Netherlands Antilles',
    dial_code: '+599',
    value: 'AN',
    icon: an,
  },
  {
    label: 'common.countries.NC',
    country_name: 'New Caledonia',
    dial_code: '+687',
    value: 'NC',
    icon: nc,
  },
  {
    label: 'common.countries.NZ',
    country_name: 'New Zealand',
    dial_code: '+64',
    value: 'NZ',
    icon: nz,
  },
  {
    label: 'common.countries.NI',
    country_name: 'Nicaragua',
    dial_code: '+505',
    value: 'NI',
    icon: ni,
  },
  {
    label: 'common.countries.NE',
    country_name: 'Niger',
    dial_code: '+227',
    value: 'NE',
    icon: ne,
  },
  {
    label: 'common.countries.NG',
    country_name: 'Nigeria',
    dial_code: '+234',
    value: 'NG',
    icon: ng,
  },
  {
    label: 'common.countries.NU',
    country_name: 'Niue',
    dial_code: '+683',
    value: 'NU',
    icon: nu,
  },
  {
    label: 'common.countries.NF',
    country_name: 'Norfolk Island',
    dial_code: '+672',
    value: 'NF',
    icon: nf,
  },
  {
    label: 'common.countries.MK',
    country_name: 'North Macedonia',
    dial_code: '+389',
    value: 'MK',
    icon: mk,
  },
  {
    label: 'common.countries.MP',
    country_name: 'Northern Mariana Islands',
    dial_code: '+1 670',
    value: 'MP',
    icon: mp,
  },
  {
    label: 'common.countries.NO',
    country_name: 'Norway',
    dial_code: '+47',
    value: 'NO',
    icon: no,
  },
  {
    label: 'common.countries.OM',
    country_name: 'Oman',
    dial_code: '+968',
    value: 'OM',
    icon: om,
  },
  {
    label: 'common.countries.PK',
    country_name: 'Pakistan',
    dial_code: '+92',
    value: 'PK',
    icon: pk,
  },
  {
    label: 'common.countries.PW',
    country_name: 'Palau',
    dial_code: '+680',
    value: 'PW',
    icon: pw,
  },
  {
    label: 'common.countries.PS',
    country_name: 'Palestine, State of',
    dial_code: '+970',
    value: 'PS',
    icon: ps,
  },
  {
    label: 'common.countries.PA',
    country_name: 'Panama',
    dial_code: '+507',
    value: 'PA',
    icon: pa,
  },
  {
    label: 'common.countries.PG',
    country_name: 'Papua New Guinea',
    dial_code: '+675',
    value: 'PG',
    icon: pg,
  },
  {
    label: 'common.countries.PY',
    country_name: 'Paraguay',
    dial_code: '+595',
    value: 'PY',
    icon: py,
  },
  {
    label: 'common.countries.PE',
    country_name: 'Peru',
    dial_code: '+51',
    value: 'PE',
    icon: pe,
  },
  {
    label: 'common.countries.PH',
    country_name: 'Philippines',
    dial_code: '+63',
    value: 'PH',
    icon: ph,
  },
  {
    label: 'common.countries.PN',
    country_name: 'Pitcairn',
    dial_code: '+872',
    value: 'PN',
    icon: pn,
  },
  {
    label: 'common.countries.PL',
    country_name: 'Poland',
    dial_code: '+48',
    value: 'PL',
    icon: pl,
  },
  {
    label: 'common.countries.PT',
    country_name: 'Portugal',
    dial_code: '+351',
    value: 'PT',
    icon: pt,
  },
  {
    label: 'common.countries.PR',
    country_name: 'Puerto Rico',
    dial_code: '+1 939',
    value: 'PR',
    icon: pr,
  },
  {
    label: 'common.countries.QA',
    country_name: 'Qatar',
    dial_code: '+974',
    value: 'QA',
    icon: qa,
  },
  {
    label: 'common.countries.RO',
    country_name: 'Romania',
    dial_code: '+40',
    value: 'RO',
    icon: ro,
  },
  {
    label: 'common.countries.RU',
    country_name: 'Russian Federation',
    dial_code: '+7',
    value: 'RU',
    icon: ru,
  },
  {
    label: 'common.countries.RW',
    country_name: 'Rwanda',
    dial_code: '+250',
    value: 'RW',
    icon: rw,
  },
  {
    label: 'common.countries.RE',
    country_name: 'Réunion',
    dial_code: '+262',
    value: 'RE',
    icon: re,
  },
  {
    label: 'common.countries.BL',
    country_name: 'Saint Barthélemy',
    dial_code: '+590',
    value: 'BL',
    icon: bl,
  },
  {
    label: 'common.countries.SH',
    country_name: 'Saint Helena, Ascension and Tristan da Cunha',
    dial_code: '+290',
    value: 'SH',
    icon: sh,
  },
  {
    label: 'common.countries.KN',
    country_name: 'Saint Kitts and Nevis',
    dial_code: '+1 869',
    value: 'KN',
    icon: kn,
  },
  {
    label: 'common.countries.LC',
    country_name: 'Saint Lucia',
    dial_code: '+1 758',
    value: 'LC',
    icon: lc,
  },
  {
    label: 'common.countries.MF',
    country_name: 'Saint Martin (French part)',
    dial_code: '+590',
    value: 'MF',
    icon: mf,
  },
  {
    label: 'common.countries.PM',
    country_name: 'Saint Pierre and Miquelon',
    dial_code: '+508',
    value: 'PM',
    icon: pm,
  },
  {
    label: 'common.countries.VC',
    country_name: 'Saint Vincent and the Grenadines',
    dial_code: '+1 784',
    value: 'VC',
    icon: vc,
  },
  {
    label: 'common.countries.WS',
    country_name: 'Samoa',
    dial_code: '+685',
    value: 'WS',
    icon: ws,
  },
  {
    label: 'common.countries.SM',
    country_name: 'San Marino',
    dial_code: '+378',
    value: 'SM',
    icon: sm,
  },
  {
    label: 'common.countries.ST',
    country_name: 'Sao Tome and Principe',
    dial_code: '+239',
    value: 'ST',
    icon: st,
  },
  {
    label: 'common.countries.SA',
    country_name: 'Saudi Arabia',
    dial_code: '+966',
    value: 'SA',
    icon: sa,
  },
  {
    label: 'common.countries.SN',
    country_name: 'Senegal',
    dial_code: '+221',
    value: 'SN',
    icon: sn,
  },
  {
    label: 'common.countries.RS',
    country_name: 'Serbia',
    dial_code: '+381',
    value: 'RS',
    icon: rs,
  },
  {
    label: 'common.countries.SC',
    country_name: 'Seychelles',
    dial_code: '+248',
    value: 'SC',
    icon: sc,
  },
  {
    label: 'common.countries.SL',
    country_name: 'Sierra Leone',
    dial_code: '+232',
    value: 'SL',
    icon: sl,
  },
  {
    label: 'common.countries.SG',
    country_name: 'Singapore',
    dial_code: '+65',
    value: 'SG',
    icon: sg,
  },
  {
    label: 'common.countries.SX',
    country_name: 'Sint Maarten (Dutch part)',
    dial_code: '+1 721',
    value: 'SX',
    icon: sx,
  },
  {
    label: 'common.countries.SK',
    country_name: 'Slovakia',
    dial_code: '+421',
    value: 'SK',
    icon: sk,
  },
  {
    label: 'common.countries.SI',
    country_name: 'Slovenia',
    dial_code: '+386',
    value: 'SI',
    icon: si,
  },
  {
    label: 'common.countries.SB',
    country_name: 'Solomon Islands',
    dial_code: '+677',
    value: 'SB',
    icon: sb,
  },
  {
    label: 'common.countries.SO',
    country_name: 'Somalia',
    dial_code: '+252',
    value: 'SO',
    icon: so,
  },
  {
    label: 'common.countries.ZA',
    country_name: 'South Africa',
    dial_code: '+27',
    value: 'ZA',
    icon: za,
  },
  {
    label: 'common.countries.GS',
    country_name: 'South Georgia and the South Sandwich Islands',
    dial_code: '+500',
    value: 'GS',
    icon: gs,
  },
  {
    label: 'common.countries.SS',
    country_name: 'South Sudan',
    dial_code: '+211',
    value: 'SS',
    icon: ss,
  },
  {
    label: 'common.countries.ES',
    country_name: 'Spain',
    dial_code: '+34',
    value: 'ES',
    icon: es,
  },
  {
    label: 'common.countries.LK',
    country_name: 'Sri Lanka',
    dial_code: '+94',
    value: 'LK',
    icon: lk,
  },
  {
    label: 'common.countries.SD',
    country_name: 'Sudan',
    dial_code: '+249',
    value: 'SD',
    icon: sd,
  },
  {
    label: 'common.countries.SR',
    country_name: 'Suriname',
    dial_code: '+597',
    value: 'SR',
    icon: sr,
  },
  {
    label: 'common.countries.SJ',
    country_name: 'Svalbard and Jan Mayen',
    dial_code: '+47',
    value: 'SJ',
    icon: sj,
  },
  {
    label: 'common.countries.SE',
    country_name: 'Sweden',
    dial_code: '+46',
    value: 'SE',
    icon: se,
  },
  {
    label: 'common.countries.CH',
    country_name: 'Switzerland',
    dial_code: '+41',
    value: 'CH',
    icon: ch,
  },
  {
    label: 'common.countries.SY',
    country_name: 'Syrian Arab Republic',
    dial_code: '+963',
    value: 'SY',
    icon: sy,
  },
  {
    label: 'common.countries.TW',
    country_name: 'Taiwan, Province of China',
    dial_code: '+886',
    value: 'TW',
    icon: tw,
  },
  {
    label: 'common.countries.TJ',
    country_name: 'Tajikistan',
    dial_code: '+992',
    value: 'TJ',
    icon: tj,
  },
  {
    label: 'common.countries.TZ',
    country_name: 'Tanzania, United Republic of',
    dial_code: '+255',
    value: 'TZ',
    icon: tz,
  },
  {
    label: 'common.countries.TH',
    country_name: 'Thailand',
    dial_code: '+66',
    value: 'TH',
    icon: th,
  },
  {
    label: 'common.countries.TL',
    country_name: 'Timor-Leste',
    dial_code: '+670',
    value: 'TL',
    icon: tl,
  },
  {
    label: 'common.countries.TG',
    country_name: 'Togo',
    dial_code: '+228',
    value: 'TG',
    icon: tg,
  },
  {
    label: 'common.countries.TK',
    country_name: 'Tokelau',
    dial_code: '+690',
    value: 'TK',
    icon: tk,
  },
  {
    label: 'common.countries.TO',
    country_name: 'Tonga',
    dial_code: '+676',
    value: 'TO',
    icon: to,
  },
  {
    label: 'common.countries.TT',
    country_name: 'Trinidad and Tobago',
    dial_code: '+1 868',
    value: 'TT',
    icon: tt,
  },
  {
    label: 'common.countries.TN',
    country_name: 'Tunisia',
    dial_code: '+216',
    value: 'TN',
    icon: tn,
  },
  {
    label: 'common.countries.TR',
    country_name: 'Turkey',
    dial_code: '+90',
    value: 'TR',
    icon: tr,
  },
  {
    label: 'common.countries.TM',
    country_name: 'Turkmenistan',
    dial_code: '+993',
    value: 'TM',
    icon: tm,
  },
  {
    label: 'common.countries.TC',
    country_name: 'Turks and Caicos Islands',
    dial_code: '+1 649',
    value: 'TC',
    icon: tc,
  },
  {
    label: 'common.countries.TV',
    country_name: 'Tuvalu',
    dial_code: '+688',
    value: 'TV',
    icon: tv,
  },
  {
    label: 'common.countries.UG',
    country_name: 'Uganda',
    dial_code: '+256',
    value: 'UG',
    icon: ug,
  },
  {
    label: 'common.countries.UA',
    country_name: 'Ukraine',
    dial_code: '+380',
    value: 'UA',
    icon: ua,
  },
  {
    label: 'common.countries.AE',
    country_name: 'United Arab Emirates',
    dial_code: '+971',
    value: 'AE',
    icon: ae,
  },
  {
    label: 'common.countries.GB',
    country_name: 'United Kingdom',
    dial_code: '+44',
    value: 'GB',
    icon: gb,
  },
  {
    label: 'common.countries.UM',
    country_name: 'United States Minor Outlying Islands',
    dial_code: '+1',
    value: 'UM',
    icon: um,
  },
  {
    label: 'common.countries.US',
    country_name: 'United States of America',
    dial_code: '+1',
    value: 'US',
    icon: us,
  },
  {
    label: 'common.countries.UY',
    country_name: 'Uruguay',
    dial_code: '+598',
    value: 'UY',
    icon: uy,
  },
  {
    label: 'common.countries.UZ',
    country_name: 'Uzbekistan',
    dial_code: '+998',
    value: 'UZ',
    icon: uz,
  },
  {
    label: 'common.countries.VU',
    country_name: 'Vanuatu',
    dial_code: '+678',
    value: 'VU',
    icon: vu,
  },
  {
    label: 'common.countries.VE',
    country_name: 'Venezuela, Bolivarian Republic of',
    dial_code: '+58',
    value: 'VE',
    icon: ve,
  },
  {
    label: 'common.countries.VN',
    country_name: 'Viet Nam',
    dial_code: '+84',
    value: 'VN',
    icon: vn,
  },
  {
    label: 'common.countries.VG',
    country_name: 'Virgin Islands, British',
    dial_code: '+1 284',
    value: 'VG',
    icon: vg,
  },
  {
    label: 'common.countries.VI',
    country_name: 'Virgin Islands, U.S.',
    dial_code: '+1 340',
    value: 'VI',
    icon: vi,
  },
  {
    label: 'common.countries.WF',
    country_name: 'Wallis and Futuna',
    dial_code: '+681',
    value: 'WF',
    icon: wf,
  },
  {
    label: 'common.countries.EH',
    country_name: 'Western Sahara',
    dial_code: '+212',
    value: 'EH',
    icon: eh,
  },
  {
    label: 'common.countries.YE',
    country_name: 'Yemen',
    dial_code: '+967',
    value: 'YE',
    icon: ye,
  },
  {
    label: 'common.countries.ZM',
    country_name: 'Zambia',
    dial_code: '+260',
    value: 'ZM',
    icon: zm,
  },
  {
    label: 'common.countries.ZW',
    country_name: 'Zimbabwe',
    dial_code: '+263',
    value: 'ZW',
    icon: zw,
  },
  {
    label: 'common.countries.AX',
    country_name: 'Åland Islands',
    dial_code: '+358',
    value: 'AX',
    icon: ax,
  },
]

const countriesMap = new Map<string, (typeof countries)[number]>()

for (const country of countries) countriesMap.set(country.value, country)

const dialCodes = [
  {
    label: '+93',
    value: '+93',
    code: 'AF',
  },
  {
    label: '+355',
    value: '+355',
    code: 'AL',
  },
  {
    label: '+213',
    value: '+213',
    code: 'DZ',
  },
  {
    label: '+1 684',
    value: '+1 684',
    code: 'AS',
  },
  {
    label: '+376',
    value: '+376',
    code: 'AD',
  },
  {
    label: '+244',
    value: '+244',
    code: 'AO',
  },
  {
    label: '+1 264',
    value: '+1 264',
    code: 'AI',
  },
  {
    label: '+672',
    value: '+672',
    code: 'AQ',
  },
  {
    label: '+1 268',
    value: '+1 268',
    code: 'AG',
  },
  {
    label: '+54',
    value: '+54',
    code: 'AR',
  },
  {
    label: '+374',
    value: '+374',
    code: 'AM',
  },
  {
    label: '+297',
    value: '+297',
    code: 'AW',
  },
  {
    label: '+247',
    value: '+247',
    code: 'AC',
  },
  {
    label: '+61',
    value: '+61',
    code: 'AU',
  },
  {
    label: '+43',
    value: '+43',
    code: 'AT',
  },
  {
    label: '+994',
    value: '+994',
    code: 'AZ',
  },
  {
    label: '+1 242',
    value: '+1 242',
    code: 'BS',
  },
  {
    label: '+973',
    value: '+973',
    code: 'BH',
  },
  {
    label: '+880',
    value: '+880',
    code: 'BD',
  },
  {
    label: '+1 246',
    value: '+1 246',
    code: 'BB',
  },
  {
    label: '+375',
    value: '+375',
    code: 'BY',
  },
  {
    label: '+32',
    value: '+32',
    code: 'BE',
  },
  {
    label: '+501',
    value: '+501',
    code: 'BZ',
  },
  {
    label: '+229',
    value: '+229',
    code: 'BJ',
  },
  {
    label: '+1 441',
    value: '+1 441',
    code: 'BM',
  },
  {
    label: '+975',
    value: '+975',
    code: 'BT',
  },
  {
    label: '+591',
    value: '+591',
    code: 'BO',
  },
  {
    label: '+599',
    value: '+599',
    code: 'BQ',
  },
  {
    label: '+387',
    value: '+387',
    code: 'BA',
  },
  {
    label: '+267',
    value: '+267',
    code: 'BW',
  },
  {
    label: '+47',
    value: '+47',
    code: 'BV',
  },
  {
    label: '+55',
    value: '+55',
    code: 'BR',
  },
  {
    label: '+246',
    value: '+246',
    code: 'IO',
  },
  {
    label: '+673',
    value: '+673',
    code: 'BN',
  },
  {
    label: '+359',
    value: '+359',
    code: 'BG',
  },
  {
    label: '+226',
    value: '+226',
    code: 'BF',
  },
  {
    label: '+257',
    value: '+257',
    code: 'BI',
  },
  {
    label: '+238',
    value: '+238',
    code: 'CV',
  },
  {
    label: '+855',
    value: '+855',
    code: 'KH',
  },
  {
    label: '+237',
    value: '+237',
    code: 'CM',
  },
  {
    label: '+1',
    value: '+1',
    code: 'CA',
  },
  {
    label: '+ 345',
    value: '+ 345',
    code: 'KY',
  },
  {
    label: '+236',
    value: '+236',
    code: 'CF',
  },
  {
    label: '+235',
    value: '+235',
    code: 'TD',
  },
  {
    label: '+56',
    value: '+56',
    code: 'CL',
  },
  {
    label: '+86',
    value: '+86',
    code: 'CN',
  },
  {
    label: '+61',
    value: '+61',
    code: 'CX',
  },
  {
    label: '+61',
    value: '+61',
    code: 'CC',
  },
  {
    label: '+57',
    value: '+57',
    code: 'CO',
  },
  {
    label: '+269',
    value: '+269',
    code: 'KM',
  },
  {
    label: '+242',
    value: '+242',
    code: 'CG',
  },
  {
    label: '+243',
    value: '+243',
    code: 'CD',
  },
  {
    label: '+682',
    value: '+682',
    code: 'CK',
  },
  {
    label: '+506',
    value: '+506',
    code: 'CR',
  },
  {
    label: '+385',
    value: '+385',
    code: 'HR',
  },
  {
    label: '+53',
    value: '+53',
    code: 'CU',
  },
  {
    label: '+599',
    value: '+599',
    code: 'CW',
  },
  {
    label: '+357',
    value: '+357',
    code: 'CY',
  },
  {
    label: '+420',
    value: '+420',
    code: 'CZ',
  },
  {
    label: '+225',
    value: '+225',
    code: 'CI',
  },
  {
    label: '+45',
    value: '+45',
    code: 'DK',
  },
  {
    label: '+253',
    value: '+253',
    code: 'DJ',
  },
  {
    label: '+1 767',
    value: '+1 767',
    code: 'DM',
  },
  {
    label: '+1 849',
    value: '+1 849',
    code: 'DO',
  },
  {
    label: '+593',
    value: '+593',
    code: 'EC',
  },
  {
    label: '+20',
    value: '+20',
    code: 'EG',
  },
  {
    label: '+503',
    value: '+503',
    code: 'SV',
  },
  {
    label: '+240',
    value: '+240',
    code: 'GQ',
  },
  {
    label: '+291',
    value: '+291',
    code: 'ER',
  },
  {
    label: '+372',
    value: '+372',
    code: 'EE',
  },
  {
    label: '+268',
    value: '+268',
    code: 'SZ',
  },
  {
    label: '+251',
    value: '+251',
    code: 'ET',
  },
  {
    label: '+500',
    value: '+500',
    code: 'FK',
  },
  {
    label: '+298',
    value: '+298',
    code: 'FO',
  },
  {
    label: '+679',
    value: '+679',
    code: 'FJ',
  },
  {
    label: '+358',
    value: '+358',
    code: 'FI',
  },
  {
    label: '+33',
    value: '+33',
    code: 'FR',
  },
  {
    label: '+594',
    value: '+594',
    code: 'GF',
  },
  {
    label: '+689',
    value: '+689',
    code: 'PF',
  },
  {
    label: '+262',
    value: '+262',
    code: 'TF',
  },
  {
    label: '+241',
    value: '+241',
    code: 'GA',
  },
  {
    label: '+220',
    value: '+220',
    code: 'GM',
  },
  {
    label: '+995',
    value: '+995',
    code: 'GE',
  },
  {
    label: '+49',
    value: '+49',
    code: 'DE',
  },
  {
    label: '+233',
    value: '+233',
    code: 'GH',
  },
  {
    label: '+350',
    value: '+350',
    code: 'GI',
  },
  {
    label: '+30',
    value: '+30',
    code: 'GR',
  },
  {
    label: '+299',
    value: '+299',
    code: 'GL',
  },
  {
    label: '+1 473',
    value: '+1 473',
    code: 'GD',
  },
  {
    label: '+590',
    value: '+590',
    code: 'GP',
  },
  {
    label: '+1 671',
    value: '+1 671',
    code: 'GU',
  },
  {
    label: '+502',
    value: '+502',
    code: 'GT',
  },
  {
    label: '+44',
    value: '+44',
    code: 'GG',
  },
  {
    label: '+224',
    value: '+224',
    code: 'GN',
  },
  {
    label: '+245',
    value: '+245',
    code: 'GW',
  },
  {
    label: '+595',
    value: '+595',
    code: 'GY',
  },
  {
    label: '+509',
    value: '+509',
    code: 'HT',
  },
  {
    label: '+672',
    value: '+672',
    code: 'HM',
  },
  {
    label: '+379',
    value: '+379',
    code: 'VA',
  },
  {
    label: '+504',
    value: '+504',
    code: 'HN',
  },
  {
    label: '+852',
    value: '+852',
    code: 'HK',
  },
  {
    label: '+36',
    value: '+36',
    code: 'HU',
  },
  {
    label: '+354',
    value: '+354',
    code: 'IS',
  },
  {
    label: '+91',
    value: '+91',
    code: 'IN',
  },
  {
    label: '+62',
    value: '+62',
    code: 'ID',
  },
  {
    label: '+98',
    value: '+98',
    code: 'IR',
  },
  {
    label: '+964',
    value: '+964',
    code: 'IQ',
  },
  {
    label: '+353',
    value: '+353',
    code: 'IE',
  },
  {
    label: '+44',
    value: '+44',
    code: 'IM',
  },
  {
    label: '+972',
    value: '+972',
    code: 'IL',
  },
  {
    label: '+39',
    value: '+39',
    code: 'IT',
  },
  {
    label: '+1 876',
    value: '+1 876',
    code: 'JM',
  },
  {
    label: '+81',
    value: '+81',
    code: 'JP',
  },
  {
    label: '+44',
    value: '+44',
    code: 'JE',
  },
  {
    label: '+962',
    value: '+962',
    code: 'JO',
  },
  {
    label: '+7 7',
    value: '+7 7',
    code: 'KZ',
  },
  {
    label: '+254',
    value: '+254',
    code: 'KE',
  },
  {
    label: '+686',
    value: '+686',
    code: 'KI',
  },
  {
    label: '+850',
    value: '+850',
    code: 'KP',
  },
  {
    label: '+82',
    value: '+82',
    code: 'KR',
  },
  {
    label: '+383',
    value: '+383',
    code: 'XK',
  },
  {
    label: '+965',
    value: '+965',
    code: 'KW',
  },
  {
    label: '+996',
    value: '+996',
    code: 'KG',
  },
  {
    label: '+856',
    value: '+856',
    code: 'LA',
  },
  {
    label: '+371',
    value: '+371',
    code: 'LV',
  },
  {
    label: '+961',
    value: '+961',
    code: 'LB',
  },
  {
    label: '+266',
    value: '+266',
    code: 'LS',
  },
  {
    label: '+231',
    value: '+231',
    code: 'LR',
  },
  {
    label: '+218',
    value: '+218',
    code: 'LY',
  },
  {
    label: '+423',
    value: '+423',
    code: 'LI',
  },
  {
    label: '+370',
    value: '+370',
    code: 'LT',
  },
  {
    label: '+352',
    value: '+352',
    code: 'LU',
  },
  {
    label: '+853',
    value: '+853',
    code: 'MO',
  },
  {
    label: '+261',
    value: '+261',
    code: 'MG',
  },
  {
    label: '+265',
    value: '+265',
    code: 'MW',
  },
  {
    label: '+60',
    value: '+60',
    code: 'MY',
  },
  {
    label: '+960',
    value: '+960',
    code: 'MV',
  },
  {
    label: '+223',
    value: '+223',
    code: 'ML',
  },
  {
    label: '+356',
    value: '+356',
    code: 'MT',
  },
  {
    label: '+692',
    value: '+692',
    code: 'MH',
  },
  {
    label: '+596',
    value: '+596',
    code: 'MQ',
  },
  {
    label: '+222',
    value: '+222',
    code: 'MR',
  },
  {
    label: '+230',
    value: '+230',
    code: 'MU',
  },
  {
    label: '+262',
    value: '+262',
    code: 'YT',
  },
  {
    label: '+52',
    value: '+52',
    code: 'MX',
  },
  {
    label: '+691',
    value: '+691',
    code: 'FM',
  },
  {
    label: '+373',
    value: '+373',
    code: 'MD',
  },
  {
    label: '+377',
    value: '+377',
    code: 'MC',
  },
  {
    label: '+976',
    value: '+976',
    code: 'MN',
  },
  {
    label: '+382',
    value: '+382',
    code: 'ME',
  },
  {
    label: '+1 664',
    value: '+1 664',
    code: 'MS',
  },
  {
    label: '+212',
    value: '+212',
    code: 'MA',
  },
  {
    label: '+258',
    value: '+258',
    code: 'MZ',
  },
  {
    label: '+95',
    value: '+95',
    code: 'MM',
  },
  {
    label: '+264',
    value: '+264',
    code: 'NA',
  },
  {
    label: '+674',
    value: '+674',
    code: 'NR',
  },
  {
    label: '+977',
    value: '+977',
    code: 'NP',
  },
  {
    label: '+31',
    value: '+31',
    code: 'NL',
  },
  {
    label: '+599',
    value: '+599',
    code: 'AN',
  },
  {
    label: '+687',
    value: '+687',
    code: 'NC',
  },
  {
    label: '+64',
    value: '+64',
    code: 'NZ',
  },
  {
    label: '+505',
    value: '+505',
    code: 'NI',
  },
  {
    label: '+227',
    value: '+227',
    code: 'NE',
  },
  {
    label: '+234',
    value: '+234',
    code: 'NG',
  },
  {
    label: '+683',
    value: '+683',
    code: 'NU',
  },
  {
    label: '+672',
    value: '+672',
    code: 'NF',
  },
  {
    label: '+389',
    value: '+389',
    code: 'MK',
  },
  {
    label: '+1 670',
    value: '+1 670',
    code: 'MP',
  },
  {
    label: '+47',
    value: '+47',
    code: 'NO',
  },
  {
    label: '+968',
    value: '+968',
    code: 'OM',
  },
  {
    label: '+92',
    value: '+92',
    code: 'PK',
  },
  {
    label: '+680',
    value: '+680',
    code: 'PW',
  },
  {
    label: '+970',
    value: '+970',
    code: 'PS',
  },
  {
    label: '+507',
    value: '+507',
    code: 'PA',
  },
  {
    label: '+675',
    value: '+675',
    code: 'PG',
  },
  {
    label: '+595',
    value: '+595',
    code: 'PY',
  },
  {
    label: '+51',
    value: '+51',
    code: 'PE',
  },
  {
    label: '+63',
    value: '+63',
    code: 'PH',
  },
  {
    label: '+872',
    value: '+872',
    code: 'PN',
  },
  {
    label: '+48',
    value: '+48',
    code: 'PL',
  },
  {
    label: '+351',
    value: '+351',
    code: 'PT',
  },
  {
    label: '+1 939',
    value: '+1 939',
    code: 'PR',
  },
  {
    label: '+974',
    value: '+974',
    code: 'QA',
  },
  {
    label: '+40',
    value: '+40',
    code: 'RO',
  },
  {
    label: '+7',
    value: '+7',
    code: 'RU',
  },
  {
    label: '+250',
    value: '+250',
    code: 'RW',
  },
  {
    label: '+262',
    value: '+262',
    code: 'RE',
  },
  {
    label: '+590',
    value: '+590',
    code: 'BL',
  },
  {
    label: '+290',
    value: '+290',
    code: 'SH',
  },
  {
    label: '+1 869',
    value: '+1 869',
    code: 'KN',
  },
  {
    label: '+1 758',
    value: '+1 758',
    code: 'LC',
  },
  {
    label: '+590',
    value: '+590',
    code: 'MF',
  },
  {
    label: '+508',
    value: '+508',
    code: 'PM',
  },
  {
    label: '+1 784',
    value: '+1 784',
    code: 'VC',
  },
  {
    label: '+685',
    value: '+685',
    code: 'WS',
  },
  {
    label: '+378',
    value: '+378',
    code: 'SM',
  },
  {
    label: '+239',
    value: '+239',
    code: 'ST',
  },
  {
    label: '+966',
    value: '+966',
    code: 'SA',
  },
  {
    label: '+221',
    value: '+221',
    code: 'SN',
  },
  {
    label: '+381',
    value: '+381',
    code: 'RS',
  },
  {
    label: '+248',
    value: '+248',
    code: 'SC',
  },
  {
    label: '+232',
    value: '+232',
    code: 'SL',
  },
  {
    label: '+65',
    value: '+65',
    code: 'SG',
  },
  {
    label: '+1 721',
    value: '+1 721',
    code: 'SX',
  },
  {
    label: '+421',
    value: '+421',
    code: 'SK',
  },
  {
    label: '+386',
    value: '+386',
    code: 'SI',
  },
  {
    label: '+677',
    value: '+677',
    code: 'SB',
  },
  {
    label: '+252',
    value: '+252',
    code: 'SO',
  },
  {
    label: '+27',
    value: '+27',
    code: 'ZA',
  },
  {
    label: '+500',
    value: '+500',
    code: 'GS',
  },
  {
    label: '+211',
    value: '+211',
    code: 'SS',
  },
  {
    label: '+34',
    value: '+34',
    code: 'ES',
  },
  {
    label: '+94',
    value: '+94',
    code: 'LK',
  },
  {
    label: '+249',
    value: '+249',
    code: 'SD',
  },
  {
    label: '+597',
    value: '+597',
    code: 'SR',
  },
  {
    label: '+47',
    value: '+47',
    code: 'SJ',
  },
  {
    label: '+46',
    value: '+46',
    code: 'SE',
  },
  {
    label: '+41',
    value: '+41',
    code: 'CH',
  },
  {
    label: '+963',
    value: '+963',
    code: 'SY',
  },
  {
    label: '+886',
    value: '+886',
    code: 'TW',
  },
  {
    label: '+992',
    value: '+992',
    code: 'TJ',
  },
  {
    label: '+255',
    value: '+255',
    code: 'TZ',
  },
  {
    label: '+66',
    value: '+66',
    code: 'TH',
  },
  {
    label: '+670',
    value: '+670',
    code: 'TL',
  },
  {
    label: '+228',
    value: '+228',
    code: 'TG',
  },
  {
    label: '+690',
    value: '+690',
    code: 'TK',
  },
  {
    label: '+676',
    value: '+676',
    code: 'TO',
  },
  {
    label: '+1 868',
    value: '+1 868',
    code: 'TT',
  },
  {
    label: '+216',
    value: '+216',
    code: 'TN',
  },
  {
    label: '+90',
    value: '+90',
    code: 'TR',
  },
  {
    label: '+993',
    value: '+993',
    code: 'TM',
  },
  {
    label: '+1 649',
    value: '+1 649',
    code: 'TC',
  },
  {
    label: '+688',
    value: '+688',
    code: 'TV',
  },
  {
    label: '+256',
    value: '+256',
    code: 'UG',
  },
  {
    label: '+380',
    value: '+380',
    code: 'UA',
  },
  {
    label: '+971',
    value: '+971',
    code: 'AE',
  },
  {
    label: '+44',
    value: '+44',
    code: 'GB',
  },
  {
    label: '+1',
    value: '+1',
    code: 'UM',
  },
  {
    label: '+1',
    value: '+1',
    code: 'US',
  },
  {
    label: '+598',
    value: '+598',
    code: 'UY',
  },
  {
    label: '+998',
    value: '+998',
    code: 'UZ',
  },
  {
    label: '+678',
    value: '+678',
    code: 'VU',
  },
  {
    label: '+58',
    value: '+58',
    code: 'VE',
  },
  {
    label: '+84',
    value: '+84',
    code: 'VN',
  },
  {
    label: '+1 284',
    value: '+1 284',
    code: 'VG',
  },
  {
    label: '+1 340',
    value: '+1 340',
    code: 'VI',
  },
  {
    label: '+681',
    value: '+681',
    code: 'WF',
  },
  {
    label: '+212',
    value: '+212',
    code: 'EH',
  },
  {
    label: '+967',
    value: '+967',
    code: 'YE',
  },
  {
    label: '+260',
    value: '+260',
    code: 'ZM',
  },
  {
    label: '+263',
    value: '+263',
    code: 'ZW',
  },
  {
    label: '+358',
    value: '+358',
    code: 'AX',
  },
]

const dialCodeMap = new Map<string, (typeof dialCodes)[number]>()
for (const code of dialCodes) dialCodeMap.set(code.code, code)
const dialCodesValues = dialCodes.map((code) => code.value)
export const useCountries = () => {
  return {
    countries,
    dialCodesValues,
    get: (val: string) => countriesMap.get(val),
  }
}
