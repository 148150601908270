import { InferRequestType, InferResponseType, crmApi } from '@services/crm-api'
import { useQuery, useMutation, keepPreviousData, useQueryClient } from '@tanstack/vue-query'
import { type Ref, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import { ServiceError } from '@epostbox/shared/errors'
import { toast } from '@epostbox/ui/sonner'

import { useUser } from '@composables/use-user'

import { CampaignsQuery } from '@modules/crm/campaigns/use-campaigns'

const $campaigns = crmApi.w[':workspaceId'].campaigns

const campaignsSortParams = ref(['name:asc', 'name:desc'])

type CampaignsGet = InferRequestType<(typeof $campaigns)['$get']>
export type CampaignsResponse = InferResponseType<(typeof $campaigns)['$get']>
export type CampaignGetResponse = CampaignsResponse['campaigns'][0]
export type CampaignsGetQuery = CampaignsGet['query']

export const useGetCampaigns = (queryParams?: CampaignsQuery) => {
  const { user } = useUser()

  const queryKey = ['campaigns', user.value!, queryParams] as const

  const {
    data: campaigns,
    error,
    ...query
  } = useQuery({
    queryKey,
    staleTime: 1000 * 30,
    placeholderData: keepPreviousData,
    queryFn: async ({ queryKey: [, user, queryParams] }) => {
      const page = queryParams?.page ? (Number(queryParams.page) < 1 ? 0 : Number(queryParams.page) - 1) : undefined
      const search = queryParams?.search && queryParams?.search !== '' ? queryParams?.search : undefined
      let sort
      if (queryParams?.sort) {
        if (Array.isArray(queryParams.sort)) {
          sort = queryParams.sort.filter(sort => campaignsSortParams.value.includes(sort))
        } else {
          sort = campaignsSortParams.value.includes(queryParams.sort) ? queryParams.sort : undefined
        }
      }

      const res = await $campaigns.$get({
        param: { workspaceId: user.workspaceId },
        query: {
          name: queryParams?.name ?? undefined,
          page: String(page),
          limit: String(queryParams?.limit || 10),
          search,
          sort,
        },
      })

      if (!res.ok) {
        throw ServiceError.fromResponse(await res.json())
      }
      const json = await res.json()
      return json
    },
  })

  return {
    campaigns,
    queryKey,
    error: error as Ref<ServiceError | null>,
    ...query,
  }
}

const campaignId = ref<string>()
const $campaign = $campaigns[':campaignId']

export type SelectedCampaignResponse = InferResponseType<(typeof $campaign)['$get']>
export type PriceModel = SelectedCampaignResponse['pricingModel']

export const useSelectedCampaign = () => {
  const { user } = useUser()
  const queryKey = ['campaign', user, campaignId] as const

  const {
    data: campaign,
    error,
    ...query
  } = useQuery({
    queryKey,
    staleTime: 1000 * 30,
    enabled: () => !!campaignId.value,
    placeholderData: keepPreviousData,
    queryFn: async ({ queryKey: [, user, campaignId] }) => {
      const res = await $campaign.$get({
        param: { workspaceId: (user?.workspaceId as string) || '', campaignId: (campaignId as string) || '' },
      })

      if (!res.ok) {
        throw ServiceError.fromResponse(await res.json())
      }
      const json = await res.json()

      return json
    },
  })

  const setCampaignId = (id?: string) => {
    campaignId.value = id || undefined
  }

  return {
    campaign,
    queryKey,
    setCampaignId,
    campaignId,
    error: error as Ref<ServiceError | null>,
    ...query,
  }
}

export const useCreateCampaign = () => {
  const { t } = useI18n()
  const { user } = useUser()
  const router = useRouter()
  const queryClient = useQueryClient()

  const $post = $campaigns.$post
  type Request = InferRequestType<typeof $post>['json']

  const {
    mutateAsync: createCampaign,
    error,
    ...mutation
  } = useMutation({
    mutationFn: async (variables: Request) => {
      const res = await $post({
        param: { workspaceId: user.value!.workspaceId },
        json: { ...variables },
      })
      if (!res.ok) {
        throw ServiceError.fromResponse(await res.json())
      }

      return res.json()
    },
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: ['campaigns'] })
    },
    onError: (_e, newConfig) => {
      toast.error(
        t('app.crm.campaigns.notifications.add.error', {
          name: newConfig.name,
        }),
        {
          dataE2e: 'add-campaigns-error',
        }
      )
    },
    onSuccess: response => {
      toast.success(
        t('app.crm.campaigns.notifications.add.success', {
          name: response.name,
        }),
        {
          dataE2e: 'add-campaigns-success',
        }
      )
      router.push('/crm/campaigns')
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['campaigns'] })
    },
  })

  return { createCampaign, error: error as Ref<ServiceError | null>, ...mutation }
}
