<script lang="ts" setup>
import type { HTMLAttributes } from 'vue'

import { cn } from '../../utils'

const props = defineProps<{
  class?: HTMLAttributes['class']
}>()
</script>

<template>
  <ol :class="cn('flex flex-wrap items-center gap-0.5 break-words text-sm text-muted-foreground', props.class)">
    <slot />
  </ol>
</template>
