export const generateChallenge = async (codeVerifier?: string) => {
  const hash = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(codeVerifier))
  return btoa(String.fromCodePoint(...new Uint8Array(hash)))
}

export const getRandomValues = (size: number) => {
  return crypto.getRandomValues(new Uint8Array(size))
}

export const random = (size: number) => {
  const mask = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-._~'
  let result = ''
  const randomUints = getRandomValues(size)
  for (let i = 0; i < size; i++) {
    // cap the value of the randomIndex to mask.length - 1
    const randomIndex = randomUints[i] % mask.length
    result += mask[randomIndex]
  }
  return result
}

export const getVerifier = (length: number): string => {
  return random(length)
}

export const createPkceCodes = async (
  length?: number
): Promise<{
  verifier: string
  challenge: string
}> => {
  if (!length) length = 43

  if (length < 43 || length > 128) {
    throw `Expected a length between 43 and 128. Received ${length}.`
  }

  const verifier = getVerifier(length)
  const challenge = await generateChallenge(verifier)

  return {
    verifier,
    challenge,
  }
}

export async function buildAuthorizeUrl(authorizeUrl: URL, isNative: boolean = false, appUrl: string) {
  const url = new URL(authorizeUrl)

  const { verifier, challenge } = await createPkceCodes(48)

  sessionStorage.setItem('code_verifier', verifier)

  url.searchParams.append('client_id', isNative ? 'app' : 'web')
  url.searchParams.append('redirect_uri', isNative ? 'tauri://nolas' : `${appUrl}`)
  url.searchParams.append('response_type', 'code')
  url.searchParams.append('code_challenge', challenge)
  url.searchParams.append('code_challenge_method', 'S256')

  return url.toString()
}

export async function getUrlParamsForAuth(appUrl: string) {
  const verifierStorage = sessionStorage.getItem('code_verifier')
  let challengeAuth
  if (verifierStorage) {
    challengeAuth = await generateChallenge(verifierStorage)
  } else {
    const { verifier, challenge } = await createPkceCodes(48)
    challengeAuth = challenge
    sessionStorage.setItem('code_verifier', verifier)
  }
  return {
    client_id: 'app',
    redirect_uri: `${appUrl}`,
    respose_type: 'code',
    code_challenge: challengeAuth,
    code_challenge_method: 'S256',
  }
}
