import { InferRequestType, InferResponseType, crmApi } from '@services/crm-api'
import { useQuery, useMutation, keepPreviousData, useQueryClient } from '@tanstack/vue-query'
import { orderBy } from 'lodash'
import { type Ref, computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { ServiceError } from '@epostbox/shared/errors'
import { truncateText } from '@epostbox/shared/helpers'
import { toast } from '@epostbox/ui/sonner'

import { useUser } from '@composables/use-user'

import { type Query } from '../organizations/use-organizations'

const $organizations = crmApi.w[':workspaceId'].organizations

export const useCreateOrganization = () => {
  const { t } = useI18n()
  const { user } = useUser()
  const queryClient = useQueryClient()

  const $post = $organizations.$post
  type Request = InferRequestType<typeof $post>['json']

  const {
    mutateAsync: createOrganization,
    error,
    ...mutation
  } = useMutation({
    mutationFn: async (variables: Request) => {
      const res = await $post({
        param: { workspaceId: user.value!.workspaceId },
        json: { ...variables },
      })
      if (!res.ok) {
        throw ServiceError.fromResponse(await res.json())
      }

      return res.json()
    },

    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: ['organizations'] })
    },

    onError: (_e, newConfig) => {
      toast.error(
        t('app.crm.organizations.notifications.add_error', {
          name: truncateText(newConfig.fullName),
        }),
        {
          dataE2e: 'add-organization-error',
        }
      )
    },

    onSuccess: response => {
      toast.success(
        t('app.crm.organizations.notifications.add', {
          name: truncateText(response.fullName),
        }),
        {
          dataE2e: 'add-organization-success',
        }
      )
    },

    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['organizations'] })
    },
  })

  return { createOrganization, error: error as Ref<ServiceError | null>, ...mutation }
}

type OrganizationsGet = InferRequestType<(typeof $organizations)['$get']>
export type OrganizationsGetResponse = InferResponseType<(typeof $organizations)['$get']>
export type OrganizationsGetQuery = OrganizationsGet['query']

export const useGetOrganizations = (queryParams: Query) => {
  const { user } = useUser()
  const queryKey = ['organizations', user.value!, queryParams] as const

  const {
    data: organizations,
    error,
    ...query
  } = useQuery({
    queryKey,
    staleTime: 1000 * 30,
    placeholderData: keepPreviousData,
    queryFn: async ({ queryKey: [, user, queryParams] }) => {
      const res = await $organizations.$get({
        param: { workspaceId: user.workspaceId },
        query: {
          page: String(queryParams.page - 1),
          limit: String(queryParams.limit),
          search: queryParams.search || undefined,
          sort: queryParams.sort,
          industry: queryParams.industry,
          city: queryParams.city,
          company_name: queryParams.company_name,
          postal_code_from: queryParams.postal_code_from,
          postal_code_to: queryParams.postal_code_to,
          groupId: queryParams.group,
        },
      })

      if (!res.ok) {
        throw ServiceError.fromResponse(await res.json())
      }
      const json = await res.json()
      return json
    },
  })

  return {
    organizations,
    queryKey,
    error: error as Ref<ServiceError | null>,
    ...query,
  }
}

const $filters = crmApi.w[':workspaceId'].filters.organizations
export const useGetOrganizationsFilters = () => {
  const { user } = useUser()

  const queryKey = ['organizations-filters', user.value!] as const

  const {
    data: organizationsFilters,
    error,
    ...query
  } = useQuery({
    queryKey,
    staleTime: 1000 * 30,
    placeholderData: keepPreviousData,
    queryFn: async ({ queryKey: [, user] }) => {
      const res = await $filters.$get({
        param: { workspaceId: user.workspaceId },
      })

      if (!res.ok) {
        throw ServiceError.fromResponse(await res.json())
      }
      const json = await res.json()
      return json
    },
  })
  const cities = computed(() => organizationsFilters.value?.cities)

  return {
    organizationsFilters,
    cities,
    error: error as Ref<ServiceError | null>,
    ...query,
  }
}

const $organization = $organizations[':organizationId']

export type SelectedOrganizationResponse = InferResponseType<(typeof $organization)['$get']>
export type ContactPersons = SelectedOrganizationResponse['contactPersons']
export type ContactPersonSingle<T extends ContactPersons> = T extends (infer U)[] ? U : never
export type ContactPerson = ContactPersonSingle<ContactPersons>
type ContactPersonExtended = ContactPerson & { phoneNumbersTypes: PhoneNumberType[] }
type PhoneNumberType = 'phone' | 'mobile' | 'other'

const organizationId = ref<string>()
const phoneNumbersTypes = ref<PhoneNumberType[]>(['phone'])
const selectedContactPerson = ref<ContactPersonExtended | undefined>()
const newContactPersonPhoneNumbersTypes = ref<PhoneNumberType[]>(['phone'])
export const useSelectedOrganization = () => {
  const { user } = useUser()
  const queryKey = ['organization', user, organizationId] as const

  const {
    data: organization,
    error,
    ...query
  } = useQuery({
    queryKey,
    staleTime: 1000 * 30,
    enabled: () => !!organizationId.value,
    placeholderData: keepPreviousData,
    queryFn: async ({ queryKey: [, user, organizationId] }) => {
      const res = await $organization.$get({
        param: { workspaceId: user?.workspaceId || '', organizationId: organizationId || '' },
      })

      if (!res.ok) {
        throw ServiceError.fromResponse(await res.json())
      }
      const json = await res.json()
      phoneNumbersTypes.value = json?.phoneNumbers?.map(phoneNumber => phoneNumber.type) || ['phone']
      return json
    },
  })

  const setOrganizationId = (id?: string) => {
    organizationId.value = id || ''
  }

  const addPhoneNumberType = (type: 'phone' | 'mobile' | 'other') => {
    phoneNumbersTypes.value.push(type)
  }

  const removePhoneNumberType = (index: number) => {
    phoneNumbersTypes.value.splice(index, 1)
  }

  const contactPersons = computed<SelectedOrganizationResponse['contactPersons']>(() =>
    orderBy(organization.value?.contactPersons, 'status', 'asc')
  )

  const addPhoneNumberTypeContactPerson = (type: 'phone' | 'mobile' | 'other') => {
    if (selectedContactPerson.value) {
      selectedContactPerson.value?.phoneNumbersTypes.push(type)
    } else {
      newContactPersonPhoneNumbersTypes.value.push(type)
    }
  }

  const removePhoneNumberTypeContactPerson = (index: number) => {
    if (selectedContactPerson.value) {
      selectedContactPerson.value?.phoneNumbersTypes.splice(index, 1)
    } else {
      newContactPersonPhoneNumbersTypes.value.splice(index, 1)
    }
  }

  return {
    organization,
    queryKey,
    contactPersons,
    phoneNumbersTypes,
    addPhoneNumberType,
    addPhoneNumberTypeContactPerson,
    removePhoneNumberType,
    removePhoneNumberTypeContactPerson,
    setOrganizationId,
    selectedContactPerson,
    newContactPersonPhoneNumbersTypes,

    organizationId,
    error: error as Ref<ServiceError | null>,
    ...query,
  }
}

const $put = $organization.$put
type Request = InferRequestType<typeof $put>['json']

export const useUpdateOrganization = () => {
  const { t } = useI18n()
  const { user } = useUser()
  const queryClient = useQueryClient()

  const {
    mutateAsync: updateOrganization,
    error,
    ...mutation
  } = useMutation({
    mutationFn: async (variables: Request & { id: SelectedOrganizationResponse['id'] }) => {
      const { id: organizationId, ...rest } = variables
      const res = await $put({
        param: { workspaceId: user.value!.workspaceId, organizationId: organizationId },
        json: { ...rest },
      })
      if (!res.ok) {
        throw ServiceError.fromResponse(await res.json())
      }

      return res.json()
    },

    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: ['organizations'] })
      await queryClient.cancelQueries({ queryKey: ['organization', user.value, organizationId.value] })
    },

    onError: (_e, newConfig) => {
      toast.error(
        t('app.crm.organizations.notifications.edit.error', {
          name: truncateText(newConfig.fullName),
        }),
        {
          dataE2e: 'edit-organization-error',
        }
      )
    },

    onSuccess: () => {
      toast.success(t('app.crm.organizations.notifications.edit.success'), {
        dataE2e: 'edit-organization-success',
      })
    },

    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['organizations'] })
      queryClient.invalidateQueries({ queryKey: ['organization', user.value, organizationId.value] })
    },
  })

  return { updateOrganization, error: error as Ref<ServiceError | null>, ...mutation }
}
