import z from 'zod'

export const OrganizationsColums = z.enum([
  'crmNumber',
  'shortName',
  'fullName',
  'additionalNames',
  'industry',
  'phoneNumber',
  'isAuthority',
  'fax',
  'country',
  'region',
  'addressLine1',
  'addressLine2',
  'streetNumber',
  'postalCode',
  'city',
  'email',
  'website',
  'socials',
  'state',
  'note',
  'campaign',
  'status',
  'createdAt',
  'updatedAt',
  'deletedAt',
])
export type OrganizationsColums = z.infer<typeof OrganizationsColums>

export const DraftsColumns = z.enum([
  'icon',
  'recipients',
  'mimeType',
  'title',
  'additionalInformation',
  'workflow',
  'classification',
  'channel',
  'pagesCount',
  'owner',
  'uploadedAt',
  'info',
])
export type DraftsColumns = z.infer<typeof DraftsColumns>

export const TrashColumns = DraftsColumns
export type TrashColumns = z.infer<typeof TrashColumns>

export const Tables = z.object({
  organizations: OrganizationsColums,
  drafts: DraftsColumns,
  trash: TrashColumns,
})
export type Tables = z.infer<typeof Tables>

export const createColumnPreferences = function <T extends z.ZodTypeAny>(itemSchema: T) {
  return z.object({
    name: itemSchema,
    show: z.boolean(),
    index: z.number().optional(),
    visible: z.boolean(),
  })
}

export type ColumnPreferences<T> = {
  name: T
  show: boolean
  index?: number
  visible: boolean
}

export const TableNames = Tables.keyof()

export const Preferences = z.record(z.string(), z.array(z.any()).or(z.any()))

export type Preferences = z.infer<typeof Preferences>

export const UserPreferencesItem = z.object({
  userId: z.string(),
  workspaceId: z.string(),
  preferences: z.record(z.string(), z.array(z.any()).or(z.any())),
})

export type UserPreferencesItem = z.infer<typeof UserPreferencesItem>
