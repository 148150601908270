import { destr } from 'destr'
import { ref, onBeforeUnmount } from 'vue'

import { NotificationMessage } from '@epostbox/notifications'
import { toast } from '@epostbox/ui/sonner'

const websocketURL = new URL(import.meta.env.VITE_WEBSOCKET_URL)

export function useNotification() {
  const websocket = ref<WebSocket>()

  const initWebSocket = (accessToken: string) => {
    websocket.value = new WebSocket(websocketURL, accessToken)

    websocket.value.addEventListener('open', _ => {})

    websocket.value.addEventListener('message', event => {
      //Double parse due to escaped string
      const notification = destr<NotificationMessage>(event.data)
      if (notification.variant === 'toast') toast.info(notification.message)
    })
  }

  onBeforeUnmount(() => {
    if (websocket.value) {
      websocket.value.close()
    }
  })

  return { websocket, initWebSocket }
}
