import { LogoutURL } from '@services/auth-api'
import { useMutation } from '@tanstack/vue-query'
import { type Ref } from 'vue'

import { ServiceError } from '@epostbox/shared/errors'

export function useLogout() {
  const {
    mutateAsync: logout,
    error,
    ...mutation
  } = useMutation({
    mutationFn: async () => {
      window.open(LogoutURL, '_self')
    },
  })

  return { logout, error: error as Ref<ServiceError | null>, ...mutation }
}
