import { hc } from 'hono/client'

import type { App } from '@epostbox/crm-api'
import { CrmAPIClient, InferBody, type InferResponse } from '@epostbox/crm-api/client'

import { accessToken } from './retrieve-access-token'

const baseUrl = '__TAURI_IPC__' in window ? import.meta.env.VITE_CRM_API_URL : `${location.origin}`

const crm = hc<App>(baseUrl, {
  fetch: async (input: RequestInfo | URL, init?: RequestInit | undefined) => {
    const headers = new Headers(init?.headers)

    if (accessToken) headers.append('Authorization', `Bearer ${accessToken}`)
    return fetch(input, { ...init, headers, credentials: 'include' })
  },
})
export const crmApi = crm.api.crm
export { type InferResponseType, type InferRequestType } from 'hono/client'

const CRM = CrmAPIClient({ baseUrl })

CRM.use({
  async onRequest({ request }) {
    if (accessToken) {
      request.headers.set('authorization', `Bearer ${accessToken}`)
    }
    return request
  },
})

export const UpdateLogin = CRM.put(
  '/api/crm/w/{workspaceId}/organizations/{organizationId}/contact-persons/{contactPersonId}/login'
)
export type UpdateLogin = InferResponse<typeof UpdateLogin>
export type UpdateLoginRequest = InferBody<typeof UpdateLogin>

// Organization Pricing Models

export const GetOrganizationPricingModels = CRM.get('/api/crm/w/{workspaceId}/organizations/{organizationId}/pricing')
export type GetOrganizationPricingModels = InferResponse<typeof GetOrganizationPricingModels>

export const CreateOrganizationPricingModel = CRM.post(
  '/api/crm/w/{workspaceId}/organizations/{organizationId}/pricing'
)

export type CreateOrganizationPricingModel = InferResponse<typeof CreateOrganizationPricingModel>
export type CreateOrganizationPricingModelRequest = InferBody<typeof CreateOrganizationPricingModel>

export const UpdateOrganizationPricingModel = CRM.put(
  '/api/crm/w/{workspaceId}/organizations/{organizationId}/pricing/{id}'
)
export type UpdateOrganizationPricingModel = InferResponse<typeof UpdateOrganizationPricingModel>
export type UpdateOrganizationPricingModelRequest = InferBody<typeof UpdateOrganizationPricingModel>

// Groups

export const GetGroupsChildless = CRM.get('/api/crm/w/{workspaceId}/groups/childless')
export type GetGroupsChildless = InferResponse<typeof GetGroupsChildless>

export const GetGroupById = CRM.get('/api/crm/w/{workspaceId}/groups/{groupId}')
export type GetGroupById = InferResponse<typeof GetGroupById>
