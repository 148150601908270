import { AuthorizeURL } from '@services/auth-api'
import { useUrlSearchParams } from '@vueuse/core'
import { ref, watch } from 'vue'

import { buildAuthorizeUrl } from '@epostbox/shared/client'

import { useNative } from './use-native'
import { useSession } from './use-session'

type AuthStates = 'pending' | 'unauthenticated' | 'authenticated' | 'redirected'

export function useAuthFlow() {
  const flowState = ref<AuthStates>('pending')
  const redirectedUrl = ref<string>()
  const searchParams = useUrlSearchParams<{ code?: string }>()

  const { status: sessionStatus } = useSession()
  const { isNative, openExternal } = useNative()

  watch(
    sessionStatus,
    async sessionStatus => {
      if (sessionStatus === 'error') {
        const authorizeUrl = await buildAuthorizeUrl(AuthorizeURL, isNative, import.meta.env.VITE_APP_URL)

        if (isNative) {
          openExternal(authorizeUrl)
          flowState.value = 'redirected'
          redirectedUrl.value = authorizeUrl
        } else {
          location.replace(authorizeUrl)
        }
      }
      if (sessionStatus === 'success') {
        flowState.value = 'authenticated'
        searchParams.code = undefined
      }
    },
    {
      immediate: true,
      deep: true,
    }
  )

  return {
    state: flowState,
    redirectedUrl,
  }
}
