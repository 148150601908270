import { cva, type VariantProps } from 'class-variance-authority'

export { default as Loader } from './loader.vue'

export const loaderVariants = cva('animate-spin', {
  variants: {
    variant: {
      primary: 'text-white',
      secondary: 'text-secondary-50',
      outline: 'text-neutral-500',
      beige: 'text-neutral-600',
      sand: 'text-neutral-700',
      social: 'text-neutral-500',
      tertiary: 'text-neutral-600',
      transparent: 'text-white',
      gray: 'text-white',
      menu: 'text-white',
      link: 'text-gray-750',
      'link-blue': 'text-brand-550',
    },
    size: {
      xs: 'w-3 h-3 min-h-3',
      sm: 'w-3.5 h-3.5 min-h-3.5',
      md: 'w-4.5 h-4.5 min-h-4.5',
      lg: 'w-4.5 h-4.5 min-h-4.5',
    },
  },
  defaultVariants: {
    variant: 'primary',
    size: 'md',
  },
})

export const strokeOpacity = cva('', {
  variants: {
    variant: {
      primary: 0.5,
      secondary: 0.5,
      outline: 1,
      beige: 0.7,
      sand: 0.7,
      social: 1,
      tertiary: 0.7,
      transparent: 0.5,
      gray: 0.5,
      menu: 0.5,
      link: 0.5,
      'link-blue': 0.5,
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
})

export const strokeColor = cva('', {
  variants: {
    variant: {
      primary: '#fff',
      secondary: '#fff',
      outline: '#EDEBEB',
      beige: '#fff',
      sand: '#fff',
      social: '#EDEBEB',
      tertiary: '#fff',
      transparent: '#fff',
      gray: '#fff',
      menu: '#fff',
      link: '#fff',
      'link-blue': '#fff',
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
})

type Variants = VariantProps<typeof loaderVariants>
export interface LoaderProps {
  variant?: Variants['variant']
  size?: Variants['size']
}
