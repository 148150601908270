import { z } from 'zod'

import { ErrorMessage } from './error-messages'

export class ServiceError extends Error {
  status: number
  name!: keyof typeof ErrorMessage
  details?: unknown
  code?: number
  internalDetails?: string

  constructor(e: { name: keyof typeof ErrorMessage; status: number; details?: unknown }) {
    super(ErrorMessage[e.name])
    this.name = e.name
    this.details = e.details
    this.status = e.status
  }

  public toResponse() {
    return {
      name: this.name,
      message: this.message,
      details: this.details,
      code: this.code,
      status: this.status,
    }
  }

  public static fromResponse(response: unknown) {
    const object = z
      .object({
        name: z.string(),
        code: z.number(),
        details: z.any(),
        status: z.number(),
      })
      .partial()
      .parse(response)

    return new ServiceError({
      name: object.name as ServiceError['name'],
      status: object.status || 0,
      details: object.details,
    })
  }
}
