import { z } from '@hono/zod-openapi'

type CrmResource = 'campaigns' | 'organizations' | 'orders' | 'prices' | 'groups' | 'pipelines'
type KeyflowzResource = 'documents'
type AccountsResource = 'saml' | 'scim' | 'domain' | 'user'

export type Resource = {
  crm: CrmResource
  keyflowz: KeyflowzResource
  accounts: AccountsResource
}
export type Module = keyof Resource
export const Module = z.custom<Module>().openapi({ type: 'string' })

export type Action = 'list' | 'add' | 'edit' | 'remove'
export type LoosePermission = '*'
type ResourcePermission<T extends Module> = `${Resource[T]}-*`
type ActionPermission<T extends Module> = `${Resource[T]}-${Action}`
export type Permission<T extends Module> = LoosePermission | ResourcePermission<T> | ActionPermission<T>

export const Effect = {
  ALLOW: 'Allow',
  DENY: 'Deny',
} as const
type Effect = (typeof Effect)[keyof typeof Effect]

type ConditionTypes = {
  user: z.ZodType<{ module?: Module }>
  groups: z.ZodType<{ groupType?: string }>
  prices: z.ZodType<{ scope?: ('CAMPAIGN' | 'ORGANIZATION' | 'WORKSPACE')[] }>
}

export const conditions: ConditionTypes = {
  user: z.object({ module: z.custom<Module>().optional().openapi({ type: 'string' }) }),
  groups: z.object({ groupType: z.string().optional() }),
  prices: z.object({ scope: z.array(z.enum(['CAMPAIGN', 'ORGANIZATION', 'WORKSPACE'])).optional() }),
}

const ResourceCondition = z.union([conditions.user, conditions.groups, conditions.prices])

export type ResourceCondition<T extends Module> = z.infer<
  ConditionTypes[Extract<Resource[T], 'user' | 'groups' | 'prices'>]
>

export const PermissionStatement = z.object({
  effect: z.enum([Effect.ALLOW, Effect.DENY]),
  permissions: z
    .array(z.custom<Permission<Module>>().openapi({ type: 'string' }))
    .openapi({ type: 'array', items: { type: 'string' } }),
  resource: Module,
  condition: z
    .object({
      module: z.custom<Module>().optional().openapi({ type: 'string' }),
      groupType: z.string().optional(),
      scope: z.array(z.enum(['CAMPAIGN', 'ORGANIZATION', 'WORKSPACE'])).optional(),
    })
    .optional(),
})

export interface PermissionStatement<T extends Module = Module> {
  effect: Effect
  permissions: Permission<T>[]
  resource: T
  condition?: ResourceCondition<T>
}

export const PermissionPolicy = z.object({
  version: z.string(),
  statement: z.array(PermissionStatement),
})

export interface PermissionPolicy {
  version: string
  statement: PermissionStatement[]
}

export const RoleNames = {
  SUPER_ADMIN: 'Super Admin',
  WORKSPACE_OWNER: 'Workspace Owner',
  CRM_ADMIN: 'CRM Admin',
  KEYFLOWZ_ADMIN: 'Keyflowz Admin',
  DISPATCHER: 'Dispatcher',
  STANDARD_USER: 'Standard User',
  /** Individual roles: */
  LOGIN_ASSIGNER: 'Login Assigner',
  SALES_COORDINATOR: 'Sales Coordinator' /** send offer and order confirmation */,
  PRICE_EDITOR: 'Price Editor' /** edit prices in the price models */,
} as const
export type RoleNames = (typeof RoleNames)[keyof typeof RoleNames]

export type WorkspaceRoleName = Exclude<RoleNames, 'Super Admin' | 'Workspace Owner'>

const workspaceRoleName: [WorkspaceRoleName, ...WorkspaceRoleName[]] = [
  'CRM Admin',
  'Dispatcher',
  'Keyflowz Admin',
  'Standard User',
  'Login Assigner',
  'Sales Coordinator',
  'Price Editor',
]

export const WorkspaceRoleName = z.enum<WorkspaceRoleName, [WorkspaceRoleName, ...WorkspaceRoleName[]]>(
  workspaceRoleName
)
export const RoleName = z.enum<RoleNames, [RoleNames, ...RoleNames[]]>([
  'Super Admin',
  'Workspace Owner',
  ...workspaceRoleName,
])

export const GroupNames = {
  SUPER_ADMIN: 'Super Admin',
  WORKSPACE_OWNER: 'Workspace Owner',
  MODULE_ADMIN: 'Module Admin',
  DISPATCHER: 'Dispatcher',
  STANDARD_USER: 'Standard User',
  RESEARCH: 'Research',
  CONTACT_DEVELOPMENT: 'Contact Development',
  CUSTOMER_CONSULTANT: 'Customer Consultant',
  RELATIONSHIP_MANAGEMENT: 'Relationship Management',
} as const
export type GroupName = (typeof GroupNames)[keyof typeof GroupNames]

export type WorkspaceGroupName = Exclude<GroupName, 'Super Admin' | 'Workspace Owner'>
export const WorkspaceGroupName: WorkspaceGroupName[] = [
  'Module Admin',
  'Dispatcher',
  'Standard User',
  'Research',
  'Contact Development',
  'Customer Consultant',
  'Relationship Management',
]
export const GroupName = z.enum<GroupName, [GroupName, ...GroupName[]]>([
  'Super Admin',
  'Workspace Owner',
  ...WorkspaceGroupName,
])

export type Can<T extends Module> = {
  resource: T
  permission: Permission<T>
}

export type RequiredCondition = {
  userId: string
  workspaceId: string
}
export type Condition<T extends Module = Module> = (ResourceCondition<T> & RequiredCondition) | RequiredCondition

export type CanPolicies = Condition & {
  policies: PermissionStatement[]
}

export interface CanPolicy<T extends Module = Module> extends Can<T> {
  condition: Condition<T>
}

export const standardUserGroups: string[] = [
  'Contact Development',
  'Customer Consultant',
  'Relationship Management',
  'Research',
] as const
export const individualRoles: string[] = ['Login Assigner', 'Sales Coordinator', 'Price Editor'] as const

export const roleGroups: Record<
  string | Exclude<RoleNames, 'Standard User' | 'Login Assigner' | 'Sales Coordinator' | 'Price Editor'>,
  GroupName
> = {
  'CRM Admin': 'Module Admin',
  'Keyflowz Admin': 'Module Admin',
  Dispatcher: 'Dispatcher',
  'Super Admin': 'Super Admin',
  'Workspace Owner': 'Workspace Owner',
}
