<script lang="ts" setup>
import { Toaster as Sonner, type ToasterProps } from '@epostbox/toast'

const props = defineProps<ToasterProps>()
</script>

<template>
  <Sonner
    class="toaster group"
    :class-names="{
      toast:
        'group toast group-[.toaster]:bg-background group-[.toaster]:text-foreground group-[.toaster]:border-border group-[.toaster]:shadow-lg',
      description: 'group-[.toast]:text-muted-foreground',
      actionButton: 'group-[.toast]:bg-primary group-[.toast]:text-primary-foreground',
    }"
    v-bind="props"
  />
</template>
