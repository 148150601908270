import { cva, type VariantProps } from 'class-variance-authority'

export { default as IconButton } from './icon-button.vue'

export const iconButtonVariants = cva(
  'inline-flex items-center justify-center font-semibold transition-all cursor-pointer',
  {
    variants: {
      variant: {
        primary: 'text-white bg-brand-550 hover:bg-brand-650 disabled:bg-neutral-350 disabled:text-neutral-450',
        secondary:
          'text-gray-1000 bg-secondary hover:bg-secondary-25 disabled:bg-neutral-375 disabled:text-neutral-475',
        outline:
          'text-brand-550 border border-gray-475 bg-transparent hover:bg-gray-warm-50 hover:text-brand-650 disabled:border-neutral-550 disabled:text-neutral-650',
        beige:
          'text-gray-1000 bg-gray-275 hover:bg-gray-550 active:text-white active:bg-brand-550 data-[state=open]:bg-brand-550  [&[data-state=open]>svg]:text-white disabled:bg-neutral-350 disabled:text-neutral-450',
        sand: 'text-gray-1000 bg-neutal-150 hover:bg-neutral-250 active:text-white active:bg-brand-550 disabled:bg-neutral-375 disabled:text-neutral-475',
        gray: 'text-gray-1000 bg-gray-warm-50 hover:bg-gray-warm-75 active:bg-gray-275 disabled:bg-neutral-375 disabled:text-neutral-475',
        social:
          'text-gray-475 border border-gray-475 bg-transparent hover:bg-gray-warm-50 hover:text-gray-475 disabled:border-neutral-550 disabled:text-neutral-650',
        tertiary:
          'bg-gray-550 hover:bg-gray-575 active:bg-brand-550 data-[state=open]:bg-brand-550 [&[data-state=open]>svg]:text-white disabled:bg-neutral-350 disabled:text-neutral-450',
        transparent: 'bg-transparent hover:bg-gray-warm-75',
      },
      size: {
        xs: 'min-w-6 w-6 h-6 p-0',
        sm: 'min-w-7 w-7 h-7 p-1',
        md: 'min-w-9 w-9 h-9 p-1.5',
        lg: 'min-w-10 w-10 h-10 p-2',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'md',
    },
  }
)

type Variants = VariantProps<typeof iconButtonVariants>
export interface IconButtonProps {
  variant?: Variants['variant']
  size?: Variants['size']
  as?: 'button' | 'a' | 'router-link'
  rounded?: boolean
  loading?: boolean
  disabled?: boolean
}
