<script lang="ts" setup>
import type { HTMLAttributes } from 'vue'

import { cn } from '../../utils'

const props = defineProps<{
  class?: HTMLAttributes['class']
}>()
</script>

<template>
  <span
    role="link"
    aria-disabled="true"
    aria-current="page"
    :class="cn('max-w-80 truncate text-xs font-medium text-gray-500', props.class)"
  >
    <slot />
  </span>
</template>
