import { CanPolicy, Module, Permission, Condition, ResourceCondition } from './types'
import { LoosePermission, PermissionStatement } from './types'

/**
 * Verifies required conditions against user's conditions
 * @param requiredCondition Condition required to access the specific resource
 * @param userCondition The current condition that the user has assigned to their permission
 * @returns Returns if the user permission has the required permission attached
 */
const hasCondition = <T extends Module>(
  requiredCondition?: ResourceCondition<T>,
  userCondition?: Partial<ResourceCondition<T>>
) => {
  if (!userCondition || !requiredCondition || Object.keys(requiredCondition).length === 0) return true

  return Object.keys(requiredCondition).some(key => {
    const typedKey = key as keyof ResourceCondition<T>

    // No condition required
    if (!requiredCondition[typedKey]) {
      return true
    }

    // User has required condition
    if (userCondition[typedKey] && requiredCondition[typedKey]) {
      if (Array.isArray(userCondition[typedKey]) && Array.isArray(requiredCondition[typedKey])) {
        const _userCondition = userCondition[typedKey] as unknown[]
        const _requiredCondition = requiredCondition[typedKey] as unknown[]

        return _userCondition.some(condition => _requiredCondition.includes(condition))
      }

      return userCondition[typedKey] === requiredCondition[typedKey]
    }
  })
}

/**
 *
 * @param requiredPolicy Required permission policy to access a specific resource in a module
 * @param userPolicies Authenticated user's permission policies
 * @returns Returns the user's permission policy that verifies the required permission policy condition
 */
export const can = (requiredPolicy: CanPolicy, userPolicies: PermissionStatement[] | LoosePermission) => {
  if (userPolicies === '*') return '*'

  const { userId: _userId, workspaceId: _workspaceId, ...condition } = requiredPolicy.condition
  for (const policy of userPolicies) {
    if (policy.resource !== requiredPolicy.resource) continue

    if (policy.permissions.includes('*')) return policy

    if (!hasCondition(condition, policy.condition)) continue

    for (const permission of policy.permissions) {
      const [userResource, userAction] = permission.split('-')
      const [requiredResource, requiredAction] = requiredPolicy.permission.split('-')

      if (userResource !== requiredResource) continue

      if (userAction === requiredAction || userAction === '*') {
        return policy
      }
    }
  }
}

export const canAccessPage = <T extends Module>(
  resource: T,
  permission: Permission<T>,
  condition: Condition<T>,
  userPolicies: '*' | PermissionStatement<T>[]
) => can({ permission, resource, condition }, userPolicies)
