import { RouteRecordRaw } from 'vue-router'

import Index from './views/index.vue'

export const routes: RouteRecordRaw[] = [
  {
    name: 'crm-organizations',
    path: '/crm',
    component: Index,
    meta: {
      title: 'crm',
      module: 'crm',
    },
    children: [
      {
        path: '',
        redirect() {
          return '/crm/organizations'
        },
        children: [],
      },
      {
        path: 'organizations',
        name: 'organizations',
        component: () => import('./views/organizations.vue'),
        meta: {
          title: 'crm-organizations',
          module: 'crm',
        },
      },
      {
        path: 'pipelines',
        name: 'crm-pipelines',
        component: () => import('./views/pipelines.vue'),
        meta: {
          title: 'crm-pipelines',
          module: 'crm',
        },
      },
      {
        path: 'campaigns',
        name: 'campaigns',
        component: () => import('./views/campaigns.vue'),
        meta: {
          title: 'crm-campaigns',
          module: 'crm',
        },
      },
      {
        path: 'campaigns/new',
        name: 'campaigns-new',
        component: () => import('./views/new-campaign.vue'),
        meta: {
          title: 'campaigns-new',
          module: 'crm',
        },
      },
      {
        path: 'calendar',
        name: 'crm-calendar',
        component: () => import('./views/calendar.vue'),
        meta: {
          title: 'crm-calendar',
          module: 'crm',
        },
      },
      {
        path: 'my-office',
        name: 'crm-office',
        component: () => import('./views/my-office.vue'),
        meta: {
          title: 'crm-office',
          module: 'crm',
        },
      },
    ],
  },
]
