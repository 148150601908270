export default {
  beforeMount(el: HTMLInputElement) {
    el.addEventListener('input', event => {
      const target = event.target as HTMLInputElement
      if (!target) return
      let value = target?.value
      // Remove any invalid characters
      value = value.replaceAll(/[^\d.]/g, '')

      // Validate the decimal places
      const parts = value.split('.')
      if (parts.length > 2) {
        // More than one decimal point is invalid
        value = parts[0] + '.' + parts.slice(1).join('')
      }

      // Limit to four digits after the decimal point
      if (parts[1] && parts[1].length > 4) {
        value = parts[0] + '.' + parts[1].slice(0, 4)
      }

      // Update the input value
      target.value = value

      // Trigger an input event to notify Vue of the change
      target.dispatchEvent(new Event('input'))
    })
  },
}
